import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { NavLink, useNavigate } from "react-router-dom";
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Parasail imports
import { DedicatedDeployment, DedicatedJsonModel, DedicatedServiceTier, PerformanceEstimate, DedicatedEngineSupport } from "react-app-env";
import {  FetchDedicatedAPI } from "service/FetchService";
import { Logger } from "utils/Logger";

const AccordionCustom = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummaryCustom = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsCustom = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface Props {
  dedicatedDeployment: DedicatedDeployment,
  modelsData: Array<DedicatedJsonModel>,
  isEditMode?: boolean,
  isCloneMode?: boolean,
  isAdmin?: boolean,
  setPerformanceData: React.Dispatch<React.SetStateAction<PerformanceEstimate>>,
  setDedicatedDeployment: React.Dispatch<React.SetStateAction<DedicatedDeployment>>,
}

type QuantizationType = {
  quantization: string; 
  preselectedQuant: boolean;
}

const SCALE_DOWN_THRESHOLD_DEFAULT = [
  {label: '15 mins', value: 900000},
  {label: '30 mins', value: 1800000},
  {label: '45 mins', value: 2700000},
  {label: '1 hr', value: 3600000},
  {label: '2 hr', value: 7200000},
  {label: '3 hr', value: 10800000},
  {label: '4 hr', value: 14400000},
  {label: '5 hr', value: 18000000},
  {label: '6 hr', value: 21600000},
  {label: '7 hr', value: 25200000},
  {label: '8 hr', value: 28800000},
  {label: '9 hr', value: 32400000},
  {label: '10 hr', value: 36000000},
  {label: '11 hr', value: 39600000},
  {label: '12 hr', value: 43200000},
  {label: '18 hr', value: 64800000},
  {label: '1 day', value: 86400000},
  {label: '2 days', value: 172800000},
  {label: '3 days', value: 259200000},
  {label: '1 week', value: 604800000},
];

const PERFORMANCE_DATA_DEFAULT = { userTps: [], cost1mt: [], tpsContext: [] };

// checks if it's a url to get the modelName part
const parseModelName = (modelName: string | undefined): string => {
  const modelNameOnly = modelName && modelName.includes("huggingface.co") ? modelName?.split("huggingface.co/")[1] : modelName || '';
  return modelNameOnly;
}

const ModelEditPane: React.FC<Props> = ({
  dedicatedDeployment,
  modelsData,
  isEditMode,
  isCloneMode,
  isAdmin,
  setPerformanceData,
  setDedicatedDeployment
}) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [tiersData, setTiersData] = React.useState(Array<DedicatedServiceTier>);
  const [engineSupportData, setEngineSupportData] = React.useState<DedicatedEngineSupport>({} as DedicatedEngineSupport);
  const [showDeployButton, setShowDeployButton] = React.useState(true);
  const [showAddChartButton, setShowAddChartButton] = React.useState(true);
  // const [loading, setLoading] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [openEditToast, setOpenEditToast] = React.useState(false);
  // const [canDeploy, setCanDeploy] = React.useState(false);
  const [isCreateMode, setIsCreateMode] = React.useState(false);
  const [isModelEngineSupport, setIsModelEngineSupport] = React.useState(false);
  const [showTierMessage, setShowTierMessage] = React.useState(false);
  const [baseModelProviderList, setBaseModelProviderList] = React.useState(Array<string>);  
  const [scaleDownThresholdList, setScaleDownThresholdList] = React.useState(Array<{label: string, value: number}>);
  const [modelFamilyList, setModelFamilyList] = React.useState(Array<string>);
  const [weightCountList, setWeightCountList] = React.useState(Array<string>);
  const [specializationList, setSpecializationList] = React.useState(Array<string>);
  const [quantizationList, setQuantizationList] = React.useState(Array<Object>);

  const [currentBaseModelProvider, setCurrentBaseModelProvider] = React.useState('');
  const [currentModelFamily, setCurrentModelFamily] = React.useState('');
  const [currentWeightCount, setCurrentWeightCount] = React.useState('');
  const [currentSpecialization, setCurrentSpecialization] = React.useState('');
  const [currentQuantization, setCurrentQuantization] = React.useState('');
  
  const [isDisabledModelFamily, setIsDisabledModelFamily] = React.useState(true);
  const [isDisabledWeightCount, setIsDisabledWeightCount] = React.useState(true);
  const [isDisabledSpecialization, setIsDisabledSpecialization] = React.useState(true);
  const [isDisabledQuantization, setIsDisabledQuantization] = React.useState(true);
  // the Guide Me model value
  const [currentModel, setCurrentModel] = React.useState(Object);

  const navigate = useNavigate();

  const disableAllButtons = () => {
    setShowDeployButton(true);
    setShowAddChartButton(true);
  };
  const enabledAllButtons = () => {
    setShowDeployButton(false);
    setShowAddChartButton(false);
  };

  React.useEffect(() => {
    setScaleDownThresholdList(SCALE_DOWN_THRESHOLD_DEFAULT);
  }, [setScaleDownThresholdList]);

  React.useEffect(() => {
    const isCreateMode = !isEditMode || !isCloneMode;
    setIsCreateMode(isCreateMode);
  }, [isEditMode, isCloneMode]);

  const updateSetDedicatedDeployment = React.useCallback((modelName: string) => {
    Logger.log('updateSetDedicatedDeployment w/ updated modelName:', modelName);
    setDedicatedDeployment(currDeployment => { return {...currDeployment, modelName: modelName} });
  }, [setDedicatedDeployment]);

  // If the model name changes, we refetch the tiers and support check
  React.useEffect(() => {
    const draftModelName = dedicatedDeployment?.draftModelName;
    const modelName = dedicatedDeployment?.modelName;

    if (modelName && modelName !== '') {
      const parsedModelName = parseModelName(modelName);
      const parsedDraftModelName = parseModelName(draftModelName);

      // save updated modelName
      Logger.log('ModelEditPane - updating parsedModelName: ', parsedModelName);
      updateSetDedicatedDeployment(parsedModelName);

      // fetch dedicated tiers
      FetchDedicatedAPI.getTiers(parsedModelName, parsedDraftModelName)
      .then((response) => {
        setTiersData(response as any);
      })
      .catch((e) => {
        Logger.log('getTiers failed.', e);
        // reset tiers on failure
        setTiersData([]);
      });

      // fetch dedicated engine support
      FetchDedicatedAPI.getEngineSupport(parsedModelName).then((response) => {
        setEngineSupportData(response as DedicatedEngineSupport);
      }).catch((e) => {
        Logger.log('getEngineSupport failed.', e);
        // reset EngineSupportData
        setEngineSupportData({} as DedicatedEngineSupport);
      });
    }
  }, [dedicatedDeployment.modelName, dedicatedDeployment.draftModelName, updateSetDedicatedDeployment]);

  React.useEffect(() => {
    // transform the modelsData (JSON payload) 
    // Note: the modelsData is the source of truth (has all the models). DO NOT update modelsData.
    if(modelsData && modelsData.length !== 0) {
      const baseModelProviderListLocal = [] as Array<string>;
      const modelFamilyListLocal = [] as Array<string>;
      const weightCountListLocal = [] as Array<string>;
      const specializationListLocal = [] as Array<string>;
      const quantizationListLocal = [] as Array<Object>;

      modelsData.forEach((item: DedicatedJsonModel) => {
        if( item.hasOwnProperty('baseModelProvider') ) {
          baseModelProviderListLocal.push(item?.baseModelProvider?.toString());
        }

        if( item.hasOwnProperty('modelFamily') ) {
          modelFamilyListLocal.push(item?.modelFamily?.toString());
        }

        if( item.hasOwnProperty('weightCount') ) {
          weightCountListLocal.push(item?.weightCount?.toString());
        }

        if( item.hasOwnProperty('specialization') ) {
          specializationListLocal.push(item?.specialization?.toString());
        }

        if(!isCreateMode && item.hasOwnProperty('quantization') && item.hasOwnProperty('preselectedQuant') ) {
          quantizationListLocal.push({quantization: item?.quantization?.toString(), preselectedQuant: item?.preselectedQuant?.toString()});
        }
      });

      // filter unique of each to display initially
      setBaseModelProviderList([...new Set(baseModelProviderListLocal)]);
      
      // only show on non-new (Edit or Clone mode)
      if(!isCreateMode) {
        setModelFamilyList([...new Set(modelFamilyListLocal)]);
        setWeightCountList([...new Set(weightCountListLocal)]);
        setSpecializationList([...new Set(specializationListLocal)]);

      // Set initial quant list. quantizationListLocal is an Array of objects
        let qList: Array<QuantizationType> = [...new Set( quantizationListLocal.map(item => JSON.stringify(item)) )].map((item: string) => JSON.parse(item));
        qList = qList.filter((item: QuantizationType, index: number) => qList.findIndex((obj: QuantizationType) => obj.quantization === item.quantization) === index);
        // force order by quantization name {None, Light, Aggressive}
        if(qList.length > 1) {
          let newList = [qList.find((item: QuantizationType) => item && item.quantization === 'None') && qList.find((item: QuantizationType) => item && item.quantization === 'None') as QuantizationType];
          newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Light') && qList.find((item: QuantizationType) => item && item.quantization === 'Light') as QuantizationType]
          newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') && qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') as QuantizationType]
          qList = newList as QuantizationType[];
        }
        setQuantizationList(qList);
      }
    }
  }, [modelsData, isCreateMode]);


  const updateDedicatedDeploymentModelName = React.useCallback((modelName: string) => {
    Logger.log('updateDedicatedDeploymentModelName w/ modelName:', modelName);
    setDedicatedDeployment(d => { return {...d, modelName} });
  }, [setDedicatedDeployment]);

  const updateDedicatedDeploymentServiceTierName = React.useCallback((serviceTierName: string) => {
    Logger.log('updateDedicatedDeploymentServiceTierName w/ serviceTierName:', serviceTierName);
    setDedicatedDeployment(d => { return {...d, serviceTierName} });
  }, [setDedicatedDeployment]);

  React.useEffect(() => {
    Logger.log('ready for recommendedModel?', currentBaseModelProvider, currentModelFamily, currentWeightCount, currentSpecialization, currentQuantization);
    if(currentBaseModelProvider !== '' && 
     currentModelFamily !== '' &&
     currentWeightCount !== '' &&
     currentSpecialization !== '' &&
     currentQuantization !== ''
    ) {
      const recommendedModel = modelsData.filter((item: DedicatedJsonModel) => 
        item.baseModelProvider === currentBaseModelProvider && 
        item.modelFamily === currentModelFamily && 
        item.weightCount === currentWeightCount &&
        item.specialization === currentSpecialization && 
        item.quantization === currentQuantization);

      Logger.log('recommendedModel', recommendedModel);
      if(recommendedModel && recommendedModel.length !== 0) {
        setCurrentModel(recommendedModel);

        const newModelName = recommendedModel[0]?.modelName;
        Logger.log('Updated model name (new)', newModelName);
        // on edit mode, allow update if value is not the same as old one
        enabledAllButtons();
        // if model name changes, we update it to the parent, and re fetch tiers and support calls
        updateDedicatedDeploymentModelName(newModelName);
      } else {
        // reset model name
        updateDedicatedDeploymentModelName('');

        // clear the perf chart
        setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      }
    }
  }, [/*canDeploy,*/ modelsData, updateDedicatedDeploymentModelName, setPerformanceData, setTiersData, currentBaseModelProvider, currentModelFamily, currentWeightCount, currentSpecialization, currentQuantization]);

  // Set if model is supported. Model might not be supported by the engine due to one of these:
  // '{modelName}' could not be found.
  // '{modelName}' isn't supported yet.
  React.useEffect(() => {
    Logger.log('engineSupportData, ', engineSupportData)
    if(engineSupportData ) {
      if (!engineSupportData?.known || !engineSupportData?.supported) {
        setIsModelEngineSupport(false);
      } else {
        setIsModelEngineSupport(true);
      }
    }
  }, [engineSupportData]);
  
  // Holistic check of all the requirements needed to allow deploy/chart actions (buttons)
  React.useEffect(() => {
    const { modelName, serviceTierName, deploymentName } = dedicatedDeployment;
    Logger.log('Something updated! Validate requirements again -', deploymentName, serviceTierName, modelName, /*canDeploy,*/ isModelEngineSupport);

    // confirm required info to enable the `deploy design/add to chart` buttons
    const isTierValid = tiersData && tiersData.length !== 0 && serviceTierName && serviceTierName !== '';
    const readyToDeploy = deploymentName && deploymentName !== '' && isTierValid && modelName && modelName !== '' /*&& canDeploy*/ && isModelEngineSupport;
    if(readyToDeploy) {
      enabledAllButtons();
    } else {
      disableAllButtons();
    }

    // If valid supported model and tiers data is empty show warning tier message
    if(modelName && modelName !== '' && isModelEngineSupport && tiersData && tiersData.length === 0) {
      setShowTierMessage(true);
    } else {
      setShowTierMessage(false);
    }

    // update to show the Guide Me defaults (Edit/Clone)
    if(modelName && modelName.trim() !== '') {
      const recommendedModel = modelsData.filter((item: DedicatedJsonModel) => 
        item.modelName === modelName);

      Logger.log('prepopulate recommendedModel ', recommendedModel);
      // found a modelName match in JSON
      setCurrentModel(recommendedModel);

      // If from JSON, set the Guide Me fields
      if(recommendedModel && recommendedModel.length !== 0) {
        setCurrentBaseModelProvider(recommendedModel[0].baseModelProvider);
        setCurrentModelFamily(recommendedModel[0].modelFamily);
        setCurrentWeightCount(recommendedModel[0].weightCount);
        setCurrentSpecialization(recommendedModel[0].specialization);
        setCurrentQuantization(recommendedModel[0].quantization);
      }
    }
  }, [dedicatedDeployment, dedicatedDeployment.modelName, dedicatedDeployment.deploymentName, modelsData, dedicatedDeployment.serviceTierName, tiersData, /*canDeploy,*/ isModelEngineSupport]);

  React.useEffect(() => {
    if(dedicatedDeployment.modelName === '') {
      //reset tier name if no current model
      updateDedicatedDeploymentServiceTierName('');
    }
  }, [dedicatedDeployment.modelName, updateDedicatedDeploymentServiceTierName]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const handleEditClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenEditToast(false);
  };

  const handleModelPanelChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      // reset tier name
      setDedicatedDeployment({...dedicatedDeployment, serviceTierName: ''});
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      // update the panel
      setExpanded(newExpanded ? panel : false);
    };

  const handleDesignNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    const deploymentName = newValue.replaceAll(/[^a-z0-9-]/g, "").trim().toLowerCase().substring(0, 32);
    setDedicatedDeployment({ ...dedicatedDeployment, deploymentName });
  };

  // Handles HF model name change
  const handleModelNameChange = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    Logger.log('Updated model name (new/old)', newValue, dedicatedDeployment.modelName);

    // reset tier name and       
    // if model name changes we re fetch tiers and support calls
    setDedicatedDeployment({ ...dedicatedDeployment, modelName: newValue, serviceTierName: '' });

    // clear the perf chart
    setPerformanceData(PERFORMANCE_DATA_DEFAULT)
  }

  const handleTierChange = (e: React.MouseEvent<HTMLElement>, newTierValue: string) => {
    const newValue = newTierValue;
    Logger.log('Updated tier name (new/old)', newValue, dedicatedDeployment.serviceTierName);

    // on edit mode, allow update if value is not the same as old one
    setDedicatedDeployment({ ...dedicatedDeployment, serviceTierName: newValue });
  }

  const handleReplicaChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    if(Number.isInteger(Number(e.target.value))) {
      let newValue = Number(e.target.value);
      Logger.log("Updated replica (new/old)", newValue, dedicatedDeployment.replicas);

      // on edit mode, allow update if value is not the same as old one
      setDedicatedDeployment({ ...dedicatedDeployment, replicas: newValue });
    }
  };

  const handleGenerativeChange = (e: React.SyntheticEvent<Element, Event>, newValue: boolean) => {
    Logger.log("Updated handleGenerativeChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, generative: Boolean(newValue) });
  };

  const handleEmbeddingChange = (e: React.SyntheticEvent<Element, Event>, newValue: boolean) => {
    Logger.log("Updated handleEmbeddingChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, embedding: Boolean(newValue) });
  };

  const handleScaleDownPolicyChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, newValue: string) => {
    Logger.log("Updated handleScaleDownPolicyChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, scaleDownPolicy: newValue });
  };

  const handleScaleDownThresholdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = Number(e.currentTarget.value);
    Logger.log("Updated handleScaleDownThresholdChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, scaleDownThreshold: newValue });
  };

  const handleCostInputTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    Logger.log("Updated handleCostInputTokenChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, costInputToken: newValue });
  };

  const handleCostOutputTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    Logger.log("Updated handleCostOutputTokenChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, costOutputToken: newValue });
  };
  
  const handleDraftModelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    Logger.log("Updated handleDraftModelNameChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, draftModelName: newValue });
  };

  const handleDisableErrorRecoveryChange = (e: React.SyntheticEvent<Element, Event>, newValue: boolean) => {
    Logger.log("Updated handleDisableErrorRecoveryChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, disableErrorRecovery: Boolean(newValue) });
  };
  
  const handleSharedChange = (e: React.SyntheticEvent<Element, Event>, newValue: boolean) => {
    Logger.log("Updated handleSharedChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, shared: Boolean(newValue) });
  };

  const handlePinnedClusterIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(Number.isInteger(Number(e.target.value))) {
      let newValue = e.target.value === '' ? undefined : Number(e.target.value);
      Logger.log("Updated handlePinnedClusterIdChange", newValue);
      setDedicatedDeployment({ ...dedicatedDeployment, pinnedClusterId: newValue });
    }
  };

  const handleImageTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    Logger.log("Updated handleImageTagChange", newValue);
    setDedicatedDeployment({ ...dedicatedDeployment, imageTag: newValue });
  };

  // Save and deploy new model
  const handleDeployDesign = () => {
    const { modelName, deploymentName, serviceTierName, id } = dedicatedDeployment;

    if(dedicatedDeployment && modelName && deploymentName && serviceTierName) {
      let newDedicatedDeployments = dedicatedDeployment;

      if(newDedicatedDeployments.replicas === 0) {
        newDedicatedDeployments.replicas = 1;
        Logger.log('replicas can not be 0.');
      }

      // Check if we're creating a new model or editing an existing one
      if (!isEditMode) {
        // Save and deploy new model
        FetchDedicatedAPI.createModelDeployment(newDedicatedDeployments).then((response) => {
          Logger.log('createModelDeployment response', response);
          // setLoading(false);
          if(response) {
            // TODO: set the response from new deploy model creation
            // TODO: route to dedicated deployments page
            navigate("/dedicated/"+response.id);
          }
        }).catch((e) => {
          setOpenToast(true);
          Logger.log(e);
        });
      } else {
        // Update and deploy model
        FetchDedicatedAPI.updateModelDeployment(id?.toString(), newDedicatedDeployments).then((response) => {
          Logger.log('updateModelDeployment response', response);
          // setLoading(false);
          // TODO: route to dedicated deployments page
          navigate(`/dedicated/${id?.toString()}`);
        }).catch((e) => {
          setOpenToast(true);
          Logger.log(e);
        });
      }
    } else {
      // show an exception in console and toast
      setOpenToast(true);
      Logger.error(`Can not save/deploy. Please check required props: modelName=${modelName}, deploymentName=${deploymentName}`)
    }
  }

  const handleAddDesignToChart = () => {
    const { modelName, serviceTierName } = dedicatedDeployment;

    if(modelName === '' && serviceTierName === '') return;

    // Get getPerformance for chart
    FetchDedicatedAPI.getPerformance(modelName, serviceTierName).then((response) => {
      setPerformanceData(response as any);
    }).catch((e) => {
      Logger.log('getPerformance failed: '+e);
      setOpenToast(true);
      disableAllButtons();
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
    });
  }

  // Check to see if the model is supported by the engine
  const getEngineSupportMessage = () => {
    const modelName = dedicatedDeployment.modelName;

    if (!modelName || modelName.trim() === "") {
      return <Alert severity="info" className="mt-1">Please enter a model ID</Alert>;
    }
    if (!engineSupportData?.known) {
      return <Alert severity="info" className="mt-1">'{modelName}' could not be found.</Alert>;
    }
    if (!engineSupportData?.supported) {
      return (
        <Alert severity="error" className="mt-1">'{modelName}' isn't supported yet. Please contact us.</Alert>
      );
    }
    if (engineSupportData?.perfWarning) {
      return (
        <Alert severity="warning" className="mt-1">
          '{modelName}' is supported with limitations: {engineSupportData?.perfWarning}
        </Alert>
      );
    }
    return <Alert severity="success" className="mt-1">'{modelName}' is supported</Alert>
  }

  const handleBaseModelProviderListChange = (e: React.MouseEvent<HTMLElement>, value: string) => {
    const newValue = value;

    if(newValue && newValue !== '') {
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      setCurrentBaseModelProvider(newValue);
      setCurrentModel(null);

      // reset disbling of child fields and enable next child only
      setIsDisabledModelFamily(false);
      setIsDisabledWeightCount(true);
      setIsDisabledSpecialization(true);
      setIsDisabledQuantization(true);

      let modelFamilyListLocal = [] as Array<string>;
      let weightCountListLocal = [] as Array<string>;
      let specializationListLocal = [] as Array<string>;
      let quantizationListLocal = [] as Array<Object>;

      modelsData.filter((item: DedicatedJsonModel) => item.baseModelProvider === newValue).forEach((item: DedicatedJsonModel) => {
        if( item.hasOwnProperty('modelFamily') ) {
          modelFamilyListLocal.push(item?.modelFamily?.toString());
        }

        if( item.hasOwnProperty('weightCount') ) {
          weightCountListLocal.push(item?.weightCount?.toString());
        }

        if( item.hasOwnProperty('specialization') ) {
          specializationListLocal.push(item?.specialization?.toString());
        }

        if( item.hasOwnProperty('quantization') && item.hasOwnProperty('preselectedQuant') ) {
          quantizationListLocal.push({quantization: item?.quantization?.toString(), preselectedQuant: item?.preselectedQuant?.toString()});
        }
      });
      
      // update these lists
      modelFamilyListLocal = [...new Set(modelFamilyListLocal)];
      setModelFamilyList(modelFamilyListLocal);
      if (modelFamilyListLocal.length === 1) {
        setCurrentModelFamily(modelFamilyListLocal[0]);

        // enable next child field for selection
        setIsDisabledWeightCount(false);
      }

      weightCountListLocal = [...new Set(weightCountListLocal)];
      setWeightCountList(weightCountListLocal);

      if (weightCountListLocal.length === 1) {
        setCurrentWeightCount(weightCountListLocal[0]);
        
        // enable next child field for selection
        setIsDisabledSpecialization(false);
      }

      specializationListLocal = [...new Set(specializationListLocal)];
      setSpecializationList(specializationListLocal);
      if (specializationListLocal.length === 1) {
        setCurrentSpecialization(specializationListLocal[0]);

        // enable next child field for selection
        setIsDisabledQuantization(false);
      }

      quantizationListLocal = [...new Set(quantizationListLocal)];
      // quantizationListLocal is an Array of objects
      let qList: Array<QuantizationType> = [...new Set( quantizationListLocal.map(item => JSON.stringify(item)) )].map((item: string) => JSON.parse(item));
      qList = qList.filter((item: QuantizationType, index: number) => qList.findIndex((obj: QuantizationType) => obj.quantization === item.quantization) === index);
      // force order by quantization name {None, Light, Aggressive}
      if(qList.length > 1) {
        let newList = [qList.find((item: QuantizationType) => item && item.quantization === 'None') && qList.find((item: QuantizationType) => item && item.quantization === 'None') as QuantizationType];
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Light') && qList.find((item: QuantizationType) => item && item.quantization === 'Light') as QuantizationType]
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') && qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') as QuantizationType]
        qList = newList as QuantizationType[];
      }
      setQuantizationList(qList);
      if (qList.length === 1) {
        setCurrentQuantization((qList[0] as QuantizationType)?.quantization);
      } else {
        const model = qList.filter((item) => (item?.preselectedQuant as unknown) === 'true');
        setCurrentQuantization((model[0] as QuantizationType)?.quantization);

        // enable next child field for selection
        //setIsDisabledQuantization(false);
      }
    }
  }

  const handleModelFamilyListChange = (e: React.MouseEvent<HTMLElement>, value: string) => {
    const newValue = value;

    if(newValue && newValue !== '') {
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      setCurrentModelFamily(newValue);
      setCurrentModel(null);
      
      // reset disbling of child fields and enable next child only
      setIsDisabledWeightCount(false);
      setIsDisabledSpecialization(true);
      setIsDisabledQuantization(true);

      let baseModelProviderListLocal = [] as Array<string>;
      let weightCountListLocal = [] as Array<string>;
      let specializationListLocal = [] as Array<string>;
      let quantizationListLocal = [] as Array<Object>;

      modelsData.filter((item: DedicatedJsonModel) => item.baseModelProvider === currentBaseModelProvider && item.modelFamily === newValue).forEach((item: DedicatedJsonModel) => {
        if( item.hasOwnProperty('baseModelProvider') ) {
          baseModelProviderListLocal.push(item?.baseModelProvider?.toString());
        }

        if( item.hasOwnProperty('weightCount') ) {
          weightCountListLocal.push(item?.weightCount?.toString());
        }

        if( item.hasOwnProperty('specialization') ) {
          specializationListLocal.push(item?.specialization?.toString());
        }

        if( item.hasOwnProperty('quantization') && item.hasOwnProperty('preselectedQuant')) {
          quantizationListLocal.push({quantization: item?.quantization?.toString(), preselectedQuant: item?.preselectedQuant?.toString()});
        }
      });

      // update these lists
      baseModelProviderListLocal = [...new Set(baseModelProviderListLocal)];
      // just set current base and not update the list
      if (baseModelProviderListLocal.length === 1) {
        setCurrentBaseModelProvider(baseModelProviderListLocal[0]);
      }

      weightCountListLocal = [...new Set(weightCountListLocal)];
      setWeightCountList(weightCountListLocal);
      if (weightCountListLocal.length === 1) {
        setCurrentWeightCount(weightCountListLocal[0]);

        // enable next child field for selection
        setIsDisabledSpecialization(false);
      }

      specializationListLocal = [...new Set(specializationListLocal)];
      setSpecializationList(specializationListLocal);
      if (specializationListLocal.length === 1) {
        setCurrentSpecialization(specializationListLocal[0]);

        // enable next child field for selection
        setIsDisabledQuantization(false);
      }

      quantizationListLocal = [...new Set(quantizationListLocal)];
      // quantizationListLocal is an Array of objects
      let qList: Array<QuantizationType> = [...new Set( quantizationListLocal.map(item => JSON.stringify(item)) )].map((item: string) => JSON.parse(item));
      qList = qList.filter((item: QuantizationType, index: number) => qList.findIndex((obj: QuantizationType) => obj.quantization === item.quantization) === index);
      // force order by quantization name {None, Light, Aggressive}
      if(qList.length > 1) {
        let newList = [qList.find((item: QuantizationType) => item && item.quantization === 'None') && qList.find((item: QuantizationType) => item && item.quantization === 'None') as QuantizationType];
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Light') && qList.find((item: QuantizationType) => item && item.quantization === 'Light') as QuantizationType]
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') && qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') as QuantizationType]
        qList = newList as QuantizationType[];
      }
      setQuantizationList(qList);
      if (qList.length === 1) {
        setCurrentQuantization((qList[0] as QuantizationType)?.quantization);
      } else {
        const model = qList.filter((item) => (item?.preselectedQuant as unknown) === 'true');
        setCurrentQuantization((model[0] as QuantizationType)?.quantization);

        // enable next child field for selection
        setIsDisabledQuantization(false);
      }
    }
  }

  const handleWeightCountListChange = (e: React.MouseEvent<HTMLElement>, value: string) => {
    const newValue = value;

    if(newValue && newValue !== '') {
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      setCurrentWeightCount(newValue);
      setCurrentModel(null);

      // reset disbling of child fields and enable next child only
      setIsDisabledSpecialization(false);
      setIsDisabledQuantization(true);

      // let baseModelProviderListLocal = [] as Array<string>;
      // let modelFamilyListLocal = [] as Array<string>;
      let specializationListLocal = [] as Array<string>;
      let quantizationListLocal = [] as Array<Object>;

      modelsData.filter((item: DedicatedJsonModel) => 
        item.baseModelProvider === currentBaseModelProvider && 
        item.modelFamily === currentModelFamily && 
        item.weightCount === newValue).forEach((item: DedicatedJsonModel) => 
      {
        // if( item.hasOwnProperty('baseModelProvider') ) {
        //   baseModelProviderListLocal.push(item?.baseModelProvider?.toString());
        // }

        // if( item.hasOwnProperty('modelFamily') ) {
        //   modelFamilyListLocal.push(item?.modelFamily?.toString());
        // }

        if( item.hasOwnProperty('specialization') ) {
          specializationListLocal.push(item?.specialization?.toString());
        }

        if( item.hasOwnProperty('quantization') && item.hasOwnProperty('preselectedQuant') ) {
          quantizationListLocal.push({quantization: item?.quantization?.toString(), preselectedQuant: item?.preselectedQuant?.toString()});
        }
      });

      // // update these lists
      // baseModelProviderListLocal = [...new Set(baseModelProviderListLocal)];
      // // just set current base and not update the list
      // if (baseModelProviderListLocal.length === 1) {
      //   setCurrentBaseModelProvider(baseModelProviderListLocal[0]);
      // }

      // modelFamilyListLocal = [...new Set(modelFamilyListLocal)];
      // setModelFamilyList(modelFamilyListLocal);
      // if (modelFamilyListLocal.length === 1) {
      //   setCurrentModelFamily(modelFamilyListLocal[0]);
      // }

      specializationListLocal = [...new Set(specializationListLocal)];
      setSpecializationList(specializationListLocal);
      if (specializationListLocal.length === 1) {
        setCurrentSpecialization(specializationListLocal[0]);

        // enable next child field for selection
        setIsDisabledQuantization(false);
      }

      quantizationListLocal = [...new Set(quantizationListLocal)];
      // quantizationListLocal is an Array of objects
      let qList: Array<QuantizationType> = [...new Set( quantizationListLocal.map(item => JSON.stringify(item)) )].map((item: string) => JSON.parse(item));
      qList = qList.filter((item: QuantizationType, index: number) => qList.findIndex((obj: QuantizationType) => obj.quantization === item.quantization) === index);
      // force order by quantization name {None, Light, Aggressive}
      if(qList.length > 1) {
        let newList = [qList.find((item: QuantizationType) => item && item.quantization === 'None') && qList.find((item: QuantizationType) => item && item.quantization === 'None') as QuantizationType];
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Light') && qList.find((item: QuantizationType) => item && item.quantization === 'Light') as QuantizationType]
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') && qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') as QuantizationType]
        qList = newList as QuantizationType[];
      }
      setQuantizationList(qList);
      if (qList.length === 1) {
        setCurrentQuantization((qList[0] as QuantizationType)?.quantization);
      } else {
        const model = qList.filter((item) => (item?.preselectedQuant as unknown) === 'true');
        setCurrentQuantization((model[0] as QuantizationType)?.quantization);

        // enable next child field for selection
        // setIsDisabledQuantization(false);
      }
    }
  }

  const handleSpecializationListChange = (e: React.MouseEvent<HTMLElement>, value: string) => {
    const newValue = value;

    if(newValue && newValue !== '') {
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      setCurrentSpecialization(newValue);
      setCurrentModel(null);

      // reset disbling of child fields and enable next child only
      setIsDisabledQuantization(false);

      // let baseModelProviderListLocal = [] as Array<string>;
      // let modelFamilyListLocal = [] as Array<string>;
      // let weightCountListLocal = [] as Array<string>;
      let quantizationListLocal = [] as Array<Object>;

      modelsData.filter((item: DedicatedJsonModel) => 
        item.baseModelProvider === currentBaseModelProvider && 
        item.modelFamily === currentModelFamily &&
        item.weightCount === currentWeightCount &&
        item.specialization === newValue).forEach((item: DedicatedJsonModel) => 
      {
        // if( item.hasOwnProperty('baseModelProvider') ) {
        //   baseModelProviderListLocal.push(item?.baseModelProvider?.toString());
        // }

        // if( item.hasOwnProperty('modelFamily') ) {
        //   modelFamilyListLocal.push(item?.modelFamily?.toString());
        // }

        // if( item.hasOwnProperty('weightCount') ) {
        //   weightCountListLocal.push(item?.weightCount?.toString());
        // }

        if( item.hasOwnProperty('quantization') && item.hasOwnProperty('preselectedQuant') ) {
          quantizationListLocal.push({quantization: item?.quantization?.toString(), preselectedQuant: item?.preselectedQuant?.toString()});
        }
      });

      // // update these lists
      // baseModelProviderListLocal = [...new Set(baseModelProviderListLocal)];
      // // just set current base and not update the list
      // if (baseModelProviderListLocal.length === 1) {
      //   setCurrentBaseModelProvider(baseModelProviderListLocal[0]);
      // }

      // modelFamilyListLocal = [...new Set(modelFamilyListLocal)];
      // setModelFamilyList(modelFamilyListLocal);
      // if (modelFamilyListLocal.length === 1) {
      //   setCurrentModelFamily(modelFamilyListLocal[0]);
      // }

      // weightCountListLocal = [...new Set(weightCountListLocal)];
      // setWeightCountList(weightCountListLocal);
      // if (weightCountListLocal.length === 1) {
      //   setCurrentWeightCount(weightCountListLocal[0]);
      // }

      quantizationListLocal = [...new Set(quantizationListLocal)];
      // quantizationListLocal is an Array of objects
      let qList: Array<QuantizationType> = [...new Set( quantizationListLocal.map(item => JSON.stringify(item)) )].map((item: string) => JSON.parse(item));
      qList = qList.filter((item: QuantizationType, index: number) => qList.findIndex((obj: QuantizationType) => obj.quantization === item.quantization) === index);
      // force order by quantization name {None, Light, Aggressive}
      if(qList.length > 1) {
        let newList = [qList.find((item: QuantizationType) => item && item.quantization === 'None') && qList.find((item: QuantizationType) => item && item.quantization === 'None') as QuantizationType];
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Light') && qList.find((item: QuantizationType) => item && item.quantization === 'Light') as QuantizationType]
        newList = [...newList, qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') && qList.find((item: QuantizationType) => item && item.quantization === 'Aggressive') as QuantizationType]
        qList = newList as QuantizationType[];
      }
      setQuantizationList(qList);
      if (qList.length === 1) {
        setCurrentQuantization((qList[0] as QuantizationType)?.quantization);
      } else {
        const model = qList.filter((item) => (item?.preselectedQuant as unknown) === 'true');
        setCurrentQuantization((model[0] as QuantizationType)?.quantization);

        // enable next child field for selection
        setIsDisabledQuantization(false);
      }
    }
  }

  const handleQuantizationListChange = (e: React.MouseEvent<HTMLElement>, value: string) => {
    const newValue = value;

    if(newValue && newValue !== '') {
      // clear the perf chart
      setPerformanceData(PERFORMANCE_DATA_DEFAULT);
      setCurrentQuantization(newValue);
      setCurrentModel(null);// reset the model display name
    }
  }

 return (
  <>
    <div className="container mx-auto bg-white dark:bg-gray-800 rounded px-4 py-4">
      <div className="xl:w-full border-b border-gray-300 dark:border-gray-700 py-2">
          <div className="flex w-11/12 mx-auto xl:w-full xl:mx-0 items-center">
              <p className="text-lg text-gray-800 dark:text-gray-100 font-bold">Configure your model</p>
          </div>
      </div>

      <div className="mx-auto pt-1">
          <div className="container mx-auto">
              <form className="my-3 w-11/12 mx-auto xl:w-full xl:mx-0">
                  {/* Model Base */}
                  <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                      <label 
                        htmlFor="designName" 
                        className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                      >
                        Design Name
                      </label>
                      <input 
                        tabIndex={0} 
                        type="text" 
                        id="designName" 
                        name="designName"
                        required 
                        className="border bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 " 
                        placeholder=""
                        // value={dedicatedDeployment && dedicatedDeployment.deploymentName ? dedicatedDeployment.deploymentName : ""}
                        value={dedicatedDeployment.deploymentName}
                        disabled={isEditMode}
                        onChange={handleDesignNameChange}
                      />
                      {/* <FormHelperText id="component-helper-text-design-name" required={isEditMode ? false : true}>
                        {isEditMode ? 'Edit mode. Read only field.' : 'Required'}
                      </FormHelperText> */}
                  </div>

                  {/* Placeholder for model's description */}
                  {/* <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
                      <label 
                        htmlFor="description" 
                        className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                      >
                        Description
                      </label>
                      <textarea 
                        tabIndex={0} 
                        id="description" 
                        name="description"
                        className="border bg-white border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500 text-gray-600 dark:text-gray-400" 
                        placeholder=""
                        value={description}
                        disabled={isEditMode}
                        onChange={handleDescriptionChange}
                      />
                  </div> */}
                  {/* End Model Base */}

                  {/* Model Selection */}
                  <div className="font-bold py-1 flex items-center text-sm text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
                    Model Selection
                  </div>
                  <p className="py-1 flex items-center text-sm text-blue-400">
                    Select one of the followings
                  </p>

                  <div className="mb-6">
                    {/* Guide me */}
                    <AccordionCustom expanded={expanded === 'panel1'} onChange={handleModelPanelChange('panel1')}>  
                      <AccordionSummaryCustom aria-controls="panel1d-content" id="panel1d-header">
                        <Typography >Guide me</Typography>
                      </AccordionSummaryCustom>
                      <AccordionDetailsCustom>
                        {/* <Typography className='flex'>
                          Please choose from our curated list of models
                        </Typography> */}
                        {/* Base Model Provider */}
                        <div className="mt-0 relative">
                          <label htmlFor="baseModelProviderList" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Base Model Provider</label>

                          <ToggleButtonGroup
                            id="base-model-provider-list"
                            color="info"
                            value={currentBaseModelProvider}
                            exclusive={true}
                            onChange={handleBaseModelProviderListChange}
                            aria-label="Base Model Provider"
                            fullWidth={false}
                            size="small"
                          >
                            {baseModelProviderList && baseModelProviderList.length !== 0 ? baseModelProviderList?.map(item => {
                              return (
                                <ToggleButton key={item} value={item} style={{textTransform :"none",minWidth: "110px"}}>
                                  <span className="text-xs">{item}</span>
                                </ToggleButton>
                              )
                            }) : <span className="text-xs">-</span>}
                          </ToggleButtonGroup>
                          {/* <FormHelperText id="component-helper-text-base-model" required={true}>
                            Required
                          </FormHelperText> */}
                        </div>

                       {/* Model Family */}
                       <div className="mt-5 relative">
                          <label htmlFor="modelFamilyList" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Model Family</label>

                          <ToggleButtonGroup
                            id="model-family-list"
                            color="info"
                            value={currentModelFamily}
                            exclusive={true}
                            onChange={handleModelFamilyListChange}
                            aria-label="Model Family"
                            fullWidth={false}
                            size="small"
                            disabled={isDisabledModelFamily}
                          >
                            {modelFamilyList && modelFamilyList.length !== 0 ? modelFamilyList?.map(item => {
                              return (
                                <ToggleButton key={item} value={item} style={{textTransform :"none",minWidth: "110px"}}>
                                  <span className="text-xs">{item}</span>
                                </ToggleButton>
                              )
                            }) : <span className="text-xs">-</span>}
                          </ToggleButtonGroup>
                          {/* <FormHelperText id="component-helper-text-model-family" required={true}>
                            Required
                          </FormHelperText> */}
                        </div>

                        {/* Weight Count */}
                        <div className="mt-5 relative">
                          <label htmlFor="weightCountList" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Weight Count</label>

                          <ToggleButtonGroup
                            id="weight-count-list"
                            color="info"
                            value={currentWeightCount}
                            exclusive={true}
                            onChange={handleWeightCountListChange}
                            aria-label="Weight Count"
                            fullWidth={false}
                            size="small"
                            disabled={isDisabledWeightCount}
                          >
                            {weightCountList && weightCountList.length !== 0 ? weightCountList?.map(item => {
                              return (
                                <ToggleButton key={item} value={item} style={{textTransform :"none",minWidth: "110px"}}>
                                  <span className="text-xs">{item}</span>
                                </ToggleButton>
                              )
                            }) : <span className="text-xs">-</span>}
                          </ToggleButtonGroup>
                          {/* <FormHelperText id="component-helper-text-weight-count" required={true}>
                            Required
                          </FormHelperText> */}
                        </div>

                        {/* Specialization */}
                        <div className="mt-5 relative">
                          <label htmlFor="specializationList" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Specialization</label>

                          <ToggleButtonGroup
                            id="specialization-list"
                            color="info"
                            value={currentSpecialization}
                            exclusive={true}
                            onChange={handleSpecializationListChange}
                            aria-label="Specialization"
                            fullWidth={false}
                            size="small"
                            disabled={isDisabledSpecialization}
                          >
                            {specializationList && specializationList.length !== 0 ? specializationList?.map(item => {
                              return (
                                <ToggleButton key={item} value={item} style={{textTransform :"none",minWidth: "110px"}}>
                                  <span className="text-xs">{item}</span>
                                </ToggleButton>
                              )
                            }) : <span className="text-xs">-</span>}
                          </ToggleButtonGroup>
                          {/* <FormHelperText id="component-helper-text-specialization" required={true}>
                            Required
                          </FormHelperText> */}
                        </div>
                        
                        {/* Quantization */}
                        <div className="mt-5 relative">
                          <label htmlFor="quantizationList" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Quantization</label>
                          <div id="quantizationList" className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col">
                            <ToggleButtonGroup
                              id="quantization-group"
                              color="info"
                              value={currentQuantization}
                              exclusive={true}
                              onChange={handleQuantizationListChange}
                              aria-label="quantization list"
                              fullWidth={false}
                              size="small"
                              disabled={isDisabledQuantization}
                            >
                              {quantizationList && quantizationList.length !== 0 ? quantizationList?.map(quantizationItem => {
                                const quantization = (quantizationItem as QuantizationType)?.quantization;
                                // const isSelected =  currentQuantization === quantization;

                                return quantizationItem && (
                                  <ToggleButton key={quantization} value={quantization} style={{textTransform :"none", minWidth: "100px"}}>
                                    <span className="text-xs">{quantization}</span>
                                  </ToggleButton>
                                )
                              }) : <span className="text-xs">No quantization.</span>}
                            </ToggleButtonGroup>
                          </div>
                          {/* <FormHelperText id="component-helper-text-quantization" required={true}>
                            Required
                          </FormHelperText> */}
                        </div>

                        {/* Recommended Model */}
                        <div className="mt-5 relative">
                          <label htmlFor="quantizationList" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Model: <strong>{(currentModel && currentModel[0]?.displayName !== undefined) && `${currentModel[0]?.displayName}`}</strong></label>
                        </div>

                      </AccordionDetailsCustom>
                    </AccordionCustom>
                    {/* Guide me ends */}

                    {/* HuggingFace URL/ID */}
                    <AccordionCustom expanded={expanded === 'panel2'} onChange={handleModelPanelChange('panel2')}>
                      <AccordionSummaryCustom aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>I'll specify the model</Typography>
                      </AccordionSummaryCustom>
                      <AccordionDetailsCustom>
                        <Typography className='flex'>
                          <span style={{marginRight: "5px"}}>Enter a</span>
                          <Link target="_blank" href="https://huggingface.co/models" underline="always">
                            HuggingFace ID or URL
                          </Link>
                        </Typography>

                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-5">
                            <label htmlFor="modelName" className="self-start pb-2 text-sm text-gray-800 dark:text-gray-100">HuggingFace ID / URL</label>
                            <input onChange={handleModelNameChange} tabIndex={0} type="text" id="modelName" name="modelName" value={dedicatedDeployment.modelName} required 
                              className="border border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500" placeholder="eg. meta-llama/Meta-Llama-3-8B-Instruct" />
                            {/* <FormHelperText id="component-helper-text-HF-model" required={true}>
                              Required
                            </FormHelperText> */}
                        </div>

                        <div className='flex alignLeft'>
                          {getEngineSupportMessage()}
                        </div>
                      </AccordionDetailsCustom>
                    </AccordionCustom>
                    {/* HuggingFace URL/ID ends */}
                  </div>
                  {/* End Model Selection */}

                  {/* Model Configure Select */}
                  <div className="font-bold py-2 flex items-center text-sm text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
                    Cloud and Service Configuration
                  </div>

                  <div className="xl:w-3/4 lg:w-3/4 md:w-3/4 flex flex-col p-3 text-sm font-medium text-gray-900 dark:text-white dark:bg-black">
                    <label
                      htmlFor="service-tiers"
                      className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                    >
                      Service Tiers
                    </label>
                    <ToggleButtonGroup
                      id="service-tiers"
                      color="info"
                      value={dedicatedDeployment.serviceTierName}
                      exclusive={true}
                      onChange={handleTierChange}
                      aria-label="Service Tiers"
                      fullWidth={false}
                      size="small"
                    >
                      
                      {tiersData && tiersData.length !== 0 ? tiersData?.map(model => {
                        return (
                          <ToggleButton key={model.serviceTierName} value={model.serviceTierName} style={{textTransform :"none",minWidth: "110px"}}>
                            <span className="text-xs">{model.serviceTierName}</span>
                          </ToggleButton>
                        )
                      }) : <span className="text-xs">-</span>}
                    </ToggleButtonGroup>
                    {(showTierMessage || dedicatedDeployment.serviceTierName) &&
                      <Alert severity={(dedicatedDeployment.serviceTierName && dedicatedDeployment.serviceTierName !== '') && !showTierMessage ? "info" : "warning"} className="mt-1">
                        {(dedicatedDeployment.serviceTierName && dedicatedDeployment.serviceTierName !== '') && 
                          <p className={dedicatedDeployment.serviceTierName && showTierMessage ? "mb-3" : ""}>
                            {dedicatedDeployment.serviceTierName && 
                              tiersData && 
                              tiersData.filter(tier => tier.serviceTierName === dedicatedDeployment.serviceTierName)[0]?.description}
                          </p>
                        }
                        {(showTierMessage && dedicatedDeployment.modelName && dedicatedDeployment.modelName !== '') && 
                          <p>{` Model '${dedicatedDeployment.modelName}' is valid, but the required hardware is currently unavailable. Please contact us. `}</p>
                        }
                      </Alert> 
                    }
                  </div>

                  {/* Advanced options */}
                  <div className="mt-5">
                    <AccordionCustom>
                      <AccordionSummaryCustom
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>Configure advanced options</Typography>
                      </AccordionSummaryCustom>
                      <AccordionDetailsCustom>
                        <Typography>
                          Optional properties for dedicated service tier deployment.
                        </Typography>

                        {/* Draft Model Name input */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="draft-model-name"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Draft Model Name
                          </label>
                          <input
                            tabIndex={0}
                            type="text"
                            id="draft-model-name"
                            name="draft-model-name"
                            className="border bg-white border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500"
                            placeholder=""
                            value={dedicatedDeployment.draftModelName}
                            onChange={handleDraftModelNameChange}
                          />
                        </div>

                        {/* Replicas input */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="replicas"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Replicas
                          </label>
                          <input
                            type="text"
                            id="replicas"
                            name="replicas"
                            className="border bg-white border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500"
                            value={dedicatedDeployment.replicas}
                            onChange={handleReplicaChange}
                          />
                        </div>

                        {/* Model Type Generative */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={dedicatedDeployment.generative} />} label="Generative Model" onChange={handleGenerativeChange}/>
                          </FormGroup>
                        </div>

                        {/* Model Type Embedding */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={dedicatedDeployment.embedding} />} label="Embedding Model" onChange={handleEmbeddingChange}/>
                          </FormGroup>
                        </div>

                        {/* AUTO SCALE DOWN */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="scale-down-policy"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Scale Down Policy
                          </label>
                          <ToggleButtonGroup
                            id="scale-down-policy"
                            color="info"
                            value={dedicatedDeployment.scaleDownPolicy}
                            exclusive={true}
                            onChange={handleScaleDownPolicyChange}
                            aria-label="scale down policy"
                            fullWidth={false}
                            size="small"
                          >
                            <ToggleButton key="NONE" value="NONE" style={{textTransform :"none", minWidth: "100px"}}>
                              <span className="text-xs">None</span>
                            </ToggleButton>
                            <ToggleButton key="TIMER" value="TIMER" style={{textTransform :"none", minWidth: "100px"}}>
                              <span className="text-xs">Timer</span>
                            </ToggleButton>
                            <ToggleButton key="INACTIVE" value="INACTIVE" style={{textTransform :"none", minWidth: "100px"}}>
                              <span className="text-xs">Inactive</span>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="scale-down-threshold"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Scale Down Threshold
                          </label>

                          <select value={dedicatedDeployment.scaleDownThreshold} onChange={handleScaleDownThresholdChange} id="scale-down-threshold" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              <option value="">Open and select</option>
                              {scaleDownThresholdList && scaleDownThresholdList?.map(item => {
                                return (
                                  <option key={item.label} value={item.value}>{item.label}</option>
                                )
                              })}
                          </select>
                          <FormHelperText id="component-helper-text-scale-down-threshol" required={false}>
                           ({dedicatedDeployment.scaleDownThreshold} ms)
                          </FormHelperText>
                        </div>
                        {/* AUTO SCALE DOWN ENDS*/}

                      {isAdmin && (<>
                        {/* Per Token Cost input */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="input-token-cost"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Cost Per Input Token
                          </label>
                          <input
                            type="text"
                            id="input-token-cost"
                            name="input-token-cost"
                            className="border bg-white border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500"
                            placeholder="1.0E-10"
                            value={dedicatedDeployment.costInputToken}
                            onChange={handleCostInputTokenChange}
                          />
                        </div>
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="output-token-cost"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Cost Per Output Token
                          </label>
                          <input
                            type="text"
                            id="output-token-cost"
                            name="output-token-cost"
                            className="border bg-white border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500"
                            placeholder="1.0E-10"
                            value={dedicatedDeployment.costOutputToken}
                            onChange={handleCostOutputTokenChange}
                          />
                        </div>

                        {/* Error Recovery input */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={dedicatedDeployment.disableErrorRecovery} />} label="Disable Error Recovery" onChange={handleDisableErrorRecoveryChange}/>
                          </FormGroup>
                        </div>

                        {/* Shared model input */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={dedicatedDeployment.shared} />} label="Shared Serverless Model" onChange={handleSharedChange}/>
                          </FormGroup>
                        </div>

                        {/* Pinned Cluster Id */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="pinned-cluster-id"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Pinned Cluster Id
                          </label>
                          <input
                            type="text"
                            id="pinned-cluster-id"
                            name="pinned-cluster-id"
                            className="border bg-white border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500"
                            value={dedicatedDeployment.pinnedClusterId}
                            onChange={handlePinnedClusterIdChange}
                          />
                        </div>

                        {/* Image Tag */}
                        <div className="xl:w-3/4 lg:w-1/2 md:w-1/2 flex flex-col mt-3">
                          <label
                            htmlFor="image-tag"
                            className="self-start font-bold pb-2 text-sm text-gray-800 dark:text-gray-100"
                          >
                            Image Tag
                          </label>
                          <input
                            type="text"
                            id="image-tag"
                            name="image-tag"
                            className="border bg-white border-gray-300 dark:border-gray-700 bg-white dark:bg-slate-400 border-gray-300 dark:border-gray-700 text-gray-600 dark:text-gray-800 pl-3 py-3 shadow-sm bg-transparent rounded text-sm focus:outline-none focus:border-indigo-700 placeholder-gray-500"
                            value={dedicatedDeployment.imageTag}
                            onChange={handleImageTagChange}
                          />
                        </div>
                      </>)}
                      </AccordionDetailsCustom>
                    </AccordionCustom>
                  </div>
                  {/* Advanced options end */}

                  {/* End Model Configure Select */}

                  {/* Model Actions */}
                  <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                    {/* <span className="flex-shrink mx-4 text-gray-400">Control</span> */}
                    <div className="flex-grow border-t border-gray-200"></div>
                  </div>
                  <Grid size={{ xs: 12 }} style={{display: "flex", justifyContent: "space-between"}}>
                    <Button variant="outlined">
                      <NavLink to={isEditMode ? dedicatedDeployment.id ? `/dedicated/${dedicatedDeployment.id}` : "/dedicated"  : "/serverless"}>
                        Go Back
                      </NavLink>
                    </Button>

                    <Stack spacing={2} direction="row">
                      <Button
                        id="deploy-btn"
                        variant="contained"
                        onClick={handleDeployDesign}
                        disabled={showDeployButton}
                      >
                        {isEditMode ? "Update" : "Deploy" }
                      </Button>

                      <Button 
                        id="add-chart-btn"
                        variant="outlined"
                        onClick={handleAddDesignToChart}
                        disabled={showAddChartButton}
                      >
                        Add to chart
                      </Button>
                    </Stack>
                  </Grid>
                  {/* End Model Actions */}
              </form>
          </div>
      </div>
    </div>

    {/* Shows an error toast when something failed on fetch */}
    <Snackbar open={openToast} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        Failed creating deployment
      </Alert>
    </Snackbar>

    {/* Shows an error toast when something failed on fetch */}
    <Snackbar open={openEditToast} onClose={handleEditClose}>
      <Alert
        onClose={handleEditClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        Can not set old value. Please choose a new value.
      </Alert>
    </Snackbar>
  </>
 )
};

export default ModelEditPane;

