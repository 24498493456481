import React from "react";
import {
  RouterProvider
} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Dark mode
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Parasail imports
import "App.css";
import { RoutesPaths } from "utils/CommonConsts";
import { Logger } from "utils/Logger";

function App() {
  const [openToast, setOpenToast] = React.useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );


  Logger.log('prefersDarkMode now!!!', prefersDarkMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={prefersDarkMode ? "App dark" : "App"}>
        <RouterProvider router={RoutesPaths} />
      </div>

      {/* Shows an error toast when something failed on fetch */}
      <Snackbar open={openToast} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Failed to load! Check network or login.
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
