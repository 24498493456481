import axios from "axios";
import { 
  Cluster, 
  ServerlessDeployment, 
  Usage, 
  DedicatedModelResponse,
  DedicatedJsonModel,
  DedicatedDeployment, 
  DedicatedServiceTier,
  DedicatedEngineSupport,
  PerformanceEstimate,
  Chat,
  User,
  Invite,
  Account,
  UserInvite,
  InviteRequest,
  Metrics,
  ApiKey,
  DedicatedLoadGenLink,
  BillingServerlessItems,
  BillingDedicatedItems
} from "react-app-env";
import type { Params } from "react-router-dom";

import { Logger } from "utils/Logger";


const jsonData = require("data/dedicated_models.json");

// Creating the axios instance
const axiosInstance = axios.create();

// interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Logger.log('Axios interceptor 401 error (not authenticated): ', error.message, window.location.pathname);
      // Handle 401 error, redirect to login. Login can throw 401 as well.
      if(window.location.pathname !== '/') {
        // similar behavior as an HTTP redirect
        Logger.log('axiosInstance.interceptor - redirecting to login with isSessionExpired=true');
        window.location.replace('/?isSessionExpired=true');
      }
    }
    return Promise.reject(error);
  },
)


// ****************************************************************************
// SERVERLESS ENDPOINTS
// Example usage: 
// import {  FetchServerlessAPI } from "./service/FetchService";
// loader: FetchServerlessAPI.getDeployments,
// ****************************************************************************

export const FetchServerlessAPI = {
  getDeployments: async () => {
    const url = "/api/v1/serverless/deployments";
    try {
      return (await axiosInstance.get(url)).data as ServerlessDeployment;
    } catch (error) {
      Logger.error("Error fetching serverless deployments " + url, error);
      return Promise.reject(error);
    }
  },
  getUsages: async (id: string | number) => {
    const url = "/api/v1/serverless/deployments/" + id + "/usage";
    try {
      return (await axiosInstance.get(url)).data as Usage;
    } catch (error) {
      Logger.error("Error fetching serverless usages " + url, error);
      return Promise.reject(error);
    }
  }
}

// ****************************************************************************
// DEDICATED ENDPOINTS
// Example usage: 
// import {  FetchDedicatedAPI } from "./service/FetchService";
// loader: FetchDedicatedAPI.getModels,
// ****************************************************************************

export const FetchDedicatedAPI = {
  getDeployments: async () => {
    const url = "/api/v1/dedicated/deployments";
    try {
      return (await axiosInstance.get(url)).data as DedicatedDeployment[];
    } catch (error) {
      Logger.error("Error fetching dedicated deployments " + url, error);
      return Promise.reject(error);
    }
  },
  getDeployment: async (id : string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id;
    try {
      return (await axiosInstance.get(url)).data as DedicatedDeployment;
    } catch (error) {
      Logger.error("Error fetching dedicated deployment " + url, error);
      return Promise.reject(error);
    }
  },
  getMetrics: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/dashboards";
    try {
      return (await axiosInstance.get(url)).data as Metrics[];
    } catch (error) {
      Logger.error("Error fetching dedicated deployments metrics (grafana)" + url, error);
      return Promise.reject(error);
    }
  },
  getLoadGenLinks: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/loadgen";
    try {
      return (await axiosInstance.get(url)).data as DedicatedLoadGenLink[];
    } catch (error) {
      Logger.error("Error fetching dedicated deployments loadgen links " + url, error);
      return Promise.reject(error);
    }
  },
  getModels: async () => {
    const url = "/api/v1/dedicated/models";
    try {
      return (await axiosInstance.get(url)).data as DedicatedModelResponse[];
    } catch (error) {
      Logger.log("Error fetching dedicated models " + url, error);
      return Promise.reject(error);
    }
  },
  getModelsFromJson: () => {
    try {
      // getting directly form JSON vs axios for now
      return Promise.resolve(jsonData as DedicatedJsonModel[]);
    } catch (error) {
      Logger.log("Error fetching dedicated models from JSON", error);
      return Promise.reject(error);
    }
  },
  getTiers: async (modelName: string, draftModelName: string) => {
    const url = "/api/v1/dedicated/tiers";
    try {
      return (
        await axiosInstance.get(url, {
          params: {
            modelName,
            draftModelName
          },
        })
      ).data as DedicatedServiceTier;
    } catch (error) {
      Logger.error("Error fetching dedicated tiers " + url, error);
      return Promise.reject(error);
    }
  },
  getPerformance: async (id: string | number, tier: string) => {
    const url = "/api/v1/dedicated/performance";
    try {
      return (
        await axiosInstance.get(url, {
          params: {
            modelName: id,
            serviceTier: tier,
          },
        })
      ).data as PerformanceEstimate;
    } catch (error) {
      Logger.error("Error fetching dedicated performance " + url, error);
      return Promise.reject(error);
    }
  },
  getEngineSupport: async (id: string | number) => {
    const url = "/api/v1/dedicated/support";
    try {
      return (
        await axiosInstance.get(url, {
          params: {
            modelName: id,
          },
        })
      ).data as DedicatedEngineSupport;
    } catch (error) {
      Logger.error("Error fetching engine support " + url, error);
      return Promise.reject(error);
    }
  },
  getUsages: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/usage";
    try {
      return (await axiosInstance.get(url)).data as Usage;
    } catch (error) {
      Logger.error("Error fetching dedicated usages " + url, error);
      return Promise.reject(error);
    }
  },
  getGateways: async () => {
    const url = "/api/v1/dedicated/gateways";
    try {
      return (await axiosInstance.get(url)).data as Cluster;
    } catch (error) {
      Logger.error("Error fetching dedicated gateways " + url, error);
      return Promise.reject(error);
    }
  },
  createModelDeployment: async (modelDeployment: DedicatedDeployment) => {
    const url = "/api/v1/dedicated/deployments";
    try {
      return (await axiosInstance.post(url, modelDeployment))
        .data as DedicatedDeployment;
    } catch (error) {
      Logger.error("Error creating dedicated model deployment " + url, error);
      return Promise.reject(error);
    }
  },
  updateModelDeployment: async (id: string | undefined, modelDeployment: DedicatedDeployment) => {
    const url = "/api/v1/dedicated/deployments/" + id;
    try {
      return (await axiosInstance.put(url, modelDeployment))
        .data as DedicatedDeployment;
    } catch (error) {
      Logger.error("Error updating dedicated model deployment " + url, error);
      return Promise.reject(error);
    }
  },
  getChatCompletions: async (url: string, key: string, chatBody: Chat) => {
    const headers = {
      'Authorization': 'Bearer ' + key,
    };
    try {
      return (await axiosInstance.post(url + "/chat/completions", chatBody, { headers })).data as any;
    } catch (error) {
      Logger.error(
        "Error fetching dedicated model chat completions " + url,
        error,
      );
    }
  },
  setDeploymentStart: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/resume";
    try {
      return (await axiosInstance.post(url)).data as any;
    } catch (error) {
      Logger.error("Error post dedicated deployment start " + url, error);
      return Promise.reject(error);
    }
  },
  setDeploymentPause: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/pause";
    try {
      return (await axiosInstance.post(url)).data as any;
    } catch (error) {
      Logger.error("Error post dedicated deployment pause " + url, error);
      return Promise.reject(error);
    }
  },
  setDeploymentDestroy: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id;
    try {
      return (await axiosInstance.delete(url)).data;
    } catch (error) {
      Logger.error("Error delete dedicated deployment " + url, error);
      return Promise.reject(error);
    }
  },
  setDeploymentKey: async (id: string | number) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/keys";
    try {
      return (await axiosInstance.post(url)).data as ApiKey;
    } catch (error) {
      Logger.error("Error post dedicated deployment key " + url, error);
      return Promise.reject(error);
    }
  },
  setDeploymentKeyDestroy: async (id: string | number, keyId: string) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/keys/" + keyId;
    try {
      return (await axiosInstance.delete(url)).data;
    } catch (error) {
      Logger.error("Error delete dedicated deployment key" + url, error);
      return Promise.reject(error);
    }
  },
  getDeploymentKeys: async ({ params }: { params: Params<"id"> }) => {
    const { id } = params;
    const url = "/api/v1/dedicated/deployments/" + id + "/keys";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      Logger.error("Error fetching dedicated deployment keys " + url, error);
      return Promise.reject(error);
    }
  },
  getDeploymentKeyById: async (id: string | number, keyId: string) => {
    const url = "/api/v1/dedicated/deployments/" + id + "/keys/" + keyId;
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      Logger.error("Error fetching dedicated deployment key by keyId " + url, error);
      return Promise.reject(error);
    }
  },
};

// ****************************************************************************
// Billing ENDPOINTS
// Example usage: 
// import {  FetchBillingAPI } from "./service/FetchService";
// loader: FetchBillingAPI.getBillingAllServerlessByTimeRange(),
// ****************************************************************************
const STATE_DATE_DEFAULT = 0;
const END_DATE_DEFAULT = Date.now();
export const FetchBillingAPI = {
  getBillingAllServerlessKeysByTimeRange: async (start: string | number=STATE_DATE_DEFAULT, end: string | number=END_DATE_DEFAULT, interval?: string | number) => {
    const url = "/api/v1/billing/serverless/keys";
    try {
      return (await axiosInstance.get(url, {
        params: interval ? {
          start,
          end,
          interval
        } : {
          start,
          end
        }
      })).data as BillingServerlessItems;
    } catch (error) {
      Logger.error("Error fetching billing for getBillingAllServerlessKeysByTimeRange" + url, error);
      return Promise.reject(error);
    }
  },
  getBillingAllServerlessDeploymentsByTimeRange: async (start: string | number=STATE_DATE_DEFAULT, end: string | number=END_DATE_DEFAULT, interval?: string | number) => {
    const url = "/api/v1/billing/serverless/deployments";
    try {
      return (await axiosInstance.get(url, {
        params: interval ? {
          start,
          end,
          interval
        } : {
          start,
          end
        }
      })).data as BillingServerlessItems;
    } catch (error) {
      Logger.error("Error fetching billing for getBillingAllServerlessDeploymentsByTimeRange" + url, error);
      return Promise.reject(error);
    }
  },
  getBillingAllDedicatedByTimeRange: async (start: string | number=STATE_DATE_DEFAULT, end: string | number=END_DATE_DEFAULT) => {
    const url = "/api/v1/billing/dedicated";
    try {
      return (await axiosInstance.get(url, {
        params: {
          start,
          end
        }
      })).data as BillingDedicatedItems;
    } catch (error) {
      Logger.error("Error fetching billing for getBillingAllDedicatedByTimeRange" + url, error);
      return Promise.reject(error);
    }
  },
  createSubscription: async () => {
    const url = "/api/v1/billing/stripe/subscriptions";
    try {
      return (await axiosInstance.post(url)).data as any;
    } catch (error) {
      console.error("Error generating subscription " + url, error);
      return Promise.reject(error);
    }
  },
  // cancelSubscription: async () => {
  //   const url = "/api/v1/billing/stripe/subscriptions";
  //   try {
  //     return (await axiosInstance.post(url)).data as any;
  //   } catch (error) {
  //     console.error("Error generating subscription " + url, error);
  //     return Promise.reject(error);
  //   }
  // },
  getSubscribed: async () => {
    const url = "/api/v1/billing/stripe/subscribed";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      console.error("Error checking subscribed " + url, error);
      return Promise.reject(error);
    }
  },
};


// ****************************************************************************
// Batch ENDPOINTS
// ****************************************************************************
export const FetchBatchAPI = {
  getBatches: async () => {
    const url = "/api/v1/batch/batches";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      console.error("Error fetching batches " + url, error);
      return Promise.reject(error);
    }
  },
  getUsages: async () => {
    const url = "/api/v1/batch/usage";
    try {
      return (await axiosInstance.get(url)).data as Usage;
    } catch (error) {
      console.error("Error fetching batch usages " + url, error);
      return Promise.reject(error);
    }
  },
  getInputUrl: async (id: string | number) => {
    const url = "/api/v1/batch/batches/" + id + "/input-url";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      console.error("Error fetching batch input url" + url, error);
      return Promise.reject(error);
    }
  },
  getOutputUrl: async (id: string | number) => {
    const url = "/api/v1/batch/batches/" + id + "/output-url";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      console.error("Error fetching batch output url" + url, error);
      return Promise.reject(error);
    }
  },
  getErrorUrl: async (id: string | number) => {
    const url = "/api/v1/batch/batches/" + id + "/error-url";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      console.error("Error fetching batch error url" + url, error);
      return Promise.reject(error);
    }
  },
  setCancel: async (id: string | number) => {
    const url = "/api/v1/batch/batches/" + id + "/cancel";
    try {
      return (await axiosInstance.post(url)).data as any;
    } catch (error) {
      console.error("Error post batch cancel" + url, error);
      return Promise.reject(error);
    }
  },
  getBatchLimits: async () => {
    const url = "/api/v1/batch/batches/limits";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      console.error("Error fetching batch limits" + url, error);
      return Promise.reject(error);
    }
  },
  setBatchUpload: async (file: string) => {
    const url = "/api/v1/batch/batches/files/upload";
    try {
      return (await axiosInstance.post(url, {file}))
        .data as any;
    } catch (error) {
      Logger.error("Error uploading batch " + url, error);
      return Promise.reject(error);
    }
  },
  updateBatchUpload: async (uploadUrl: string, fileObject: any) => {
    const url = uploadUrl;
    try {
      return (await axiosInstance.put(url, fileObject))
        .data as any;
    } catch (error) {
      Logger.error("Error uploading file to S3" + url, error);
      return Promise.reject(error);
    }
  },
  createBatch: async (fileId: string, metadata: object) => {
    const url = "/api/v1/batch/batches/create";
    try {
      return (await axiosInstance.post(url, {inputFileId: fileId, metadata}))
        .data as any;
    } catch (error) {
      Logger.error("Error creating a batch " + url, error);
      return Promise.reject(error);
    }
  },
}


// ****************************************************************************
// KEY ENDPOINTS
// ****************************************************************************
export const FetchKeyAPI = {
  createKey: async (activeAccount: Account) => {
    const url = "/api/v1/keys";

    try {
      return (await axiosInstance.post(url, activeAccount)).data as ApiKey;
    } catch (error) {
      Logger.error("Error create new key " + url, error);
      return Promise.reject(error);
    }
  },
  setKeyDestroy: async (keyId: string) => {
    const url = "/api/v1/keys/" + keyId;
    try {
      return (await axiosInstance.delete(url)).data;
    } catch (error) {
      Logger.error("Error delete key" + url, error);
      return Promise.reject(error);
    }
  },
  getKeys: async () => {
    const url = "/api/v1/keys";
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      Logger.error("Error fetching keys " + url, error);
      return Promise.reject(error);
    }
  },
  getKeyById: async (id: string | number, keyId: string) => {
    const url = "/api/v1/keys" + keyId;
    try {
      return (await axiosInstance.get(url)).data as any;
    } catch (error) {
      Logger.error("Error fetching key by keyId " + url, error);
      return Promise.reject(error);
    }
  },
}

// ****************************************************************************
// COMMON ENDPOINTS
// Example usage: 
// import {  FetchCommonAPI } from "./service/FetchService";
// loader: FetchCommonAPI.getAccount(),
// ****************************************************************************
export const FetchCommonAPI = {
  setInvite: async (inviteCode: Invite) => {
    const url = "/api/v1/account-onboarding/invite";
    try {
      return (await axiosInstance.post(url, inviteCode)).data as Account;
    } catch (error) {
      Logger.error("Error post invite code " + url, error);
      return Promise.reject(error);
    }
  },
  setAccount: async (accountName: string) => {
    const url = "/api/v1/account-onboarding/existing";
    try {
      return (await axiosInstance.post(url, { accountName })).data as Account;
    } catch (error) {
      Logger.error("Error post account name " + url, error);
      return Promise.reject(error);
    }
  },
  getAccount: async () => {
    const url = "/api/v1/user-profile/account";
    try {
      return (await axiosInstance.get(url)).data as Account;
    } catch (error) {
      Logger.error("Error fetching user-profile account" + url, error);
      return Promise.reject(error);
    }
  },
  getUser: async () => {
    const url = "/api/v1/user-profile";
    try {
      return (await axiosInstance.get(url)).data as User;
    } catch (error) {
      Logger.error("Error fetching user-profile user" + url, error);
      return Promise.reject(error);
    }
  },
  getAccounts: async () => {
    const url = "/api/v1/user-profile/accounts";
    try {
      return (await axiosInstance.get(url)).data as Account[];
    } catch (error) {
      Logger.error("Error fetching user accounts" + url, error);
      return Promise.reject(error);
    }
  },
  getBillingServerless: async (start: string | number, end: string | number) => {
    const url = "/api/v1/billing/serverless";
    try {
      return (await axiosInstance.get(url, {
        params: {
          start,
          end
        }
      })).data as any[];
    } catch (error) {
      Logger.error("Error fetching billing for serverless" + url, error);
      return Promise.reject(error);
    }
  },
  createInvite: async (inviteRequest: InviteRequest) => {
    const url = "/api/v1/user-invites/generate";
    try {
      return (await axiosInstance.post(url, inviteRequest)).data as UserInvite;
    } catch (error) {
      Logger.error("Error generating invite code " + url, error);
      return Promise.reject(error);
    }
  },
  logout: async () => {
    const url = "/api/v1/user-profile/logout";
    try {
      const response = await axiosInstance.post(url);// 302/redirect
      return Promise.resolve(response);
    } catch (error) {
      Logger.error("Error logging out " + url, error);
      return Promise.reject(error);
    }
  },
};

// ****************************************************************************
// ALL ENDPOINTS
// Usage: FetchAllAPI.getModels();
// ****************************************************************************

export const FetchAllAPI = {
  FetchServerlessAPI,
  FetchDedicatedAPI,
  FetchBillingAPI,
  FetchBatchAPI,
  FetchKeyAPI,
  FetchCommonAPI
}