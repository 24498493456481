import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Storage from '@mui/icons-material/Storage';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Build from '@mui/icons-material/Build';
import Link from '@mui/material/Link';
import { useSearchParams } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

// Parasail imports
import PageHeader from "components/PageHeader"
import MiniCard from 'components/views/common/MiniCard';
import { useUserContext } from "components/providers/UserContext";
import { Logger } from "utils/Logger";

const HomeView = () => {
  const [isInviteFailure, setIsInviteFailure] = React.useState(false);
  const [isInviteSuccess, setIsInviteSuccess] = React.useState(false);
  const [inviteFailureReason, setInviteFailureReason] = React.useState('');
  const [hasActiveAccount, setHasActiveAccount] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [activeAccountName, setActiveAccountName] = React.useState('');
  
  const { UserState, isUserContextLoading } = useUserContext();

  // get any params from url
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if(UserState && UserState.hasActiveAccount) {
      setHasActiveAccount(UserState.hasActiveAccount);
    }

    if(UserState && UserState.user.username) {
      setUsername(UserState.user.username);
    }

    if(UserState &&  
       UserState.activeAccount && 
       UserState.activeAccount.accountName) {
        setActiveAccountName( UserState.activeAccount.accountName);
    }

  }, [UserState, UserState.hasActiveAccount, UserState.user, setHasActiveAccount, setUsername, setActiveAccountName]);


  React.useEffect(() => {
    const isInviteFailureParam = searchParams.size !== 0 && searchParams.get("inviteFailure");
    const inviteSuccessParam = searchParams.size !== 0 && searchParams.get("inviteSuccess");
    const inviteFailureReasonParam = searchParams.size !== 0 && searchParams.get("inviteFailureReason");

    if(isInviteFailureParam && isInviteFailureParam.length !== 0) {
      setIsInviteFailure(isInviteFailureParam === 'true' ? true : false);
    }

    if(inviteSuccessParam && inviteSuccessParam.length !== 0) {
      setIsInviteSuccess(inviteSuccessParam === 'true' ? true : false);
    }

    if(inviteFailureReasonParam) {
      setInviteFailureReason(inviteFailureReasonParam);
    }
  }, [searchParams]);

  if (isUserContextLoading) {
    return <div></div>;
  }


  const ServerlessContent = () => {
    return (
      <ul style={{minHeight: "170px", listStyle: "disc", paddingLeft: "10px"}}>
        <li className="mb-2" style={{textAlign: "start"}}>
          Easy access to popular LLMs and multimodal models
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Market leading price performance
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Real-time and batch endpoints for performance and cost-optimized workloads
        </li>
      </ul>
    )
  }
  const DedicatedContent = () => {
    return (
      <ul style={{minHeight: "170px", listStyle: "disc", paddingLeft: "10px"}}>
        <li className="mb-2" style={{textAlign: "start"}}>
          Custom models, optimized orchestration, with a latency and uptime SLAs
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Powered by on-demand GPUs at the most competitive price
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Secure and private: you control access to the data and GPUs
        </li>
      </ul>
    )
  }
  const ManagedContent = () => {
    return (
      <ul style={{minHeight: "170px", listStyle: "disc", paddingLeft: "10px"}}>
        <li className="mb-2" style={{textAlign: "start"}}>
          Ultimate level of security, privacy, and multi-cloud flexibility: run endpoints or even our full platform in your cloud environment
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Use your GPUs, our low-priced on-demand GPUs, or both
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Enterprise-grade integrations for data, security and compliance, business processes, and MLOps
        </li>
      </ul>
    )
  }

  Logger.log('isInviteFailure', isInviteFailure, searchParams.get("inviteFailure"));
  Logger.log('Is userState info: ', UserState.hasActiveAccount, UserState.user );
  
  const welcomeMessage = hasActiveAccount ? `Welcome, ${username}!` : `Welcome to Parasail!`;

  return (
    <>
      { isInviteSuccess && (
        <>
          { activeAccountName ? 
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              You have successfully joined <strong>{activeAccountName}</strong>!
            </Alert>
          :
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              You have successfully joined!
            </Alert>
          }
        </>
      )}

      <PageHeader title={welcomeMessage} />

      { hasActiveAccount ? (     
          <div style={{display: "flex", alignItems: "stretch", justifyContent: "center"}}>
            {isInviteFailure ?
              <Stack direction="column" alignItems="center" justifyContent="center" height="80vh">
                  <Alert severity="warning">
                    {inviteFailureReason}
                  </Alert>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Don't have an invite code? We're currently in closed beta. <br />
                    Contact us 
                    <Link className="pl-1 pr-1" target="_blank" variant="body1" href="mailto:hello@parasail.io" underline="always" sx={{'&:hover': {cursor: 'pointer'}}}>
                      here
                    </Link>
                    to get access.
                </Typography>  
              </Stack>
            :
            <Box className="w-full" style={{marginBottom: "25px"}} sx={{ bgcolor: 'background.default'}}>
              <Grid container spacing={4}>
                <Grid size={{ xs: 12 }} sx={{ marginTop: "15px", textAlign: "start", display: "flex", justifyContent: "center"}}>
                    <Typography variant="h4" component="div">
                      Serverless, dedicated, and managed enterprise tiers to fit any requirement.
                    </Typography>
                </Grid>
                
                <Grid size={{ xs: 4 }}>
                  <MiniCard cardTitle='Serverless' cardBtnText="Try Serverless" cardLink="/serverless" CardIcon={<Storage fontSize="large" color="primary" />} CardBody={ServerlessContent} />
                </Grid>
                <Grid size={{ xs: 4 }}>
                  <MiniCard cardTitle='Dedicated' cardBtnText="Try Dedicated" cardLink="/dedicated" CardIcon={<CloudDoneIcon fontSize="large" color="primary" />} CardBody={DedicatedContent} />
                </Grid>
                <Grid size={{ xs: 4 }}>
                  <MiniCard cardTitle='Managed Enterprise' cardBtnText="Try Managed" cardLink="/managed" CardIcon={<Build fontSize="large" color="primary" />} CardBody={ManagedContent} />
                </Grid>
              </Grid>
            </Box>
            }
          </div>
        ) : (
          <Stack direction="column" alignItems="center" justifyContent="center" height="80vh">
            <Typography gutterBottom variant="subtitle1" component="div" className="bg-gray-200 p-3" style={{textAlign: "center"}}>
                You're not currently part of any organization. <br/>
                Please click the invite link you received previously.<br/>
                Don't have an invite link? <br />
                <Link className="pl-1 pr-1" target="_blank" variant="body1" href="mailto:hello@parasail.io" underline="always" sx={{'&:hover': {cursor: 'pointer'}}}>
                Contact us 
                </Link>
                to get access.
            </Typography>  
          </Stack>
        )}
    </>
  );
};

export default HomeView;