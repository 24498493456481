import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Drafts from '@mui/icons-material/Drafts';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Parasail imports
import { useUserContext } from "components/providers/UserContext";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FetchCommonAPI } from 'service/FetchService';
import NavbarActions from "components/views/common/NavbarActions";
import 'styles/common.css';
import { Logger } from "utils/Logger";

export default function Navbar(props: { toggleDrawer: (newOpen: boolean) => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = React.useState(false);
  const [currentPathname, setCurrentPathname] = React.useState<string>('');

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();
  let location = useLocation();
  const { UserContextService, UserState, isUserContextLoading } = useUserContext();

  // check the location and save the latest path to invoke later
  React.useEffect(() => {
    Logger.log('location', location);

    if(location) {
      const pathName = location.pathname;
      if(pathName) {
        setCurrentPathname(pathName);
      }
    }
  }, [location, setCurrentPathname]);

  if (isUserContextLoading) {
    return <div></div>;
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMyProfileRoute = () => {
    navigate("/settings");
  }

  const handleCreateInviteRoute = () => {
    navigate("/invite");
  }

  const handleBillingRoute = () => {
    navigate("/billing");
  }

  const handleKeysRoute = () => {
    navigate("/keys");
  }

  const menuId = 'primary-search-account-menu';

  const SharedMenuItems = (<div>
        {/* {UserState.user.username && (
        <MenuItem>Hi, {UserState.user.username}</MenuItem>
        )}
        <Divider /> */}
        {UserState.activeAccount.accountName && (
          <div>
            <Typography
                className="pl-4 text-neutral-400"
                variant="body1"
                noWrap
                sx={{ display: { xs: 'none', sm: 'block' } }}
            >
                Organization
            </Typography>
            <MenuItem
              onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
              style={{ width: "100%" }}
            >
              {UserState.activeAccount.accountName}
              {isAccountMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={isAccountMenuOpen} timeout="auto" unmountOnExit sx={{ mb: 1 }}>
              <List component="div" disablePadding>
                {UserState.accounts.map((a, index) => {
                  const indexFormatted = index + 1;
                  return (
                  <ListItemButton
                    key={indexFormatted}
                    sx={{ padding: 0, margin: 0, pl: 1 }}
                    onClick={() => {                         
                      FetchCommonAPI.setAccount(a.accountName || '')
                        .then(() => UserContextService.setActiveAccount(a))
                        .then(() => setIsAccountMenuOpen(false))
                        .then(() => {
                          if(location) {
                            navigate(currentPathname);
                          }
                        })
                      }}>
                    {UserState.activeAccount.accountName !== a.accountName ? 
                      <ListItemText primary={`Switch to ${a.accountName}`} />
                      :
                      ''
                    }
                  </ListItemButton>
                )})}
              </List>
            </Collapse>
          </div>
        )}
        <MenuItem onClick={handleCreateInviteRoute}>Invite Member</MenuItem>
        <MenuItem onClick={handleBillingRoute}>Billing</MenuItem>
        <MenuItem onClick={handleKeysRoute}>API Keys</MenuItem>
        <MenuItem onClick={handleMyProfileRoute}>Settings</MenuItem>
        {/* <Divider /> */}

        <Typography
            className="pt-4 pl-4 text-neutral-400"
            variant="body1"
            noWrap
            sx={{ display: { xs: 'none', sm: 'block' } }}
        >
            Solution
        </Typography>
        <MenuItem onClick={() => navigate("/serverless")}>Serverless</MenuItem>
        <MenuItem onClick={() => navigate("/dedicated")}>Dedicated</MenuItem>
        <MenuItem onClick={() => navigate("/batch")}>Batch</MenuItem>
        <MenuItem onClick={() => navigate("/managed")}>Managed</MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/price")}>Pricing</MenuItem>
        <Divider />
        <MenuItem>                 
          <button type="submit"
            onClick={ () => {
              UserContextService.Logout();
            }}>
            Log Out
          </button>
        </MenuItem>
  </div>);
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {SharedMenuItems}
      </Menu>
    </>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className='bg-white'>
        <Button onClick={handleCreateInviteRoute} color="info" variant="outlined" endIcon={<Drafts />} size="small" style={{backgroundColor: "#fff", textTransform :"none"}}>
          Create Invite
        </Button>
      </MenuItem>
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
        {SharedMenuItems}
    </Menu>
  );

  Logger.log('Navbar called - isAuthenticated?', UserState.isAuthenticated);
  return (
    <div
      id="navbar"
      className="col-span-12 h-[3.75rem] flex items-center bg-white"
    >
        <Box sx={{ flexGrow: 1 }}>
          {/* {!showLogoutContent ?  */}
          {/* <ThemeProvider
              theme={createTheme({
              components: {
                  MuiListItemButton: {
                  defaultProps: {
                      disableTouchRipple: true,
                  },
                  },
              },
              palette: {
                  mode: 'dark',
                  primary: { main: 'rgb(102, 157, 246)' },
                  background: { paper: '#3477EC' },
              },
              })}
          > */}
              <AppBar elevation={0} color="primary" position="static" enableColorOnDark={true} >    
                  <Toolbar>
                      <Box>
                        {/* {isAdmin &&
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 0 }}
                                onClick={() => props.toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                          } */}
                          <Tooltip title="Home page">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="parasail icon"
                                sx={{ mr: 2 }}
                            >
                              {/* <Link to='/home'><img src="/parasail.svg" alt="parasail logo" style={{ height: "55px"}}/></Link> */}
                              <NavLink to='/home' className="company-text--light">
                                  PARASAIL
                              </NavLink>
                            </IconButton>
                          </Tooltip>
                          {/* <Typography
                              variant="h6"
                              noWrap
                              component="div"
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                          >
                              Parasail
                          </Typography> 
                          <Search>
                              <SearchIconWrapper>
                              <SearchIcon />
                              </SearchIconWrapper>
                              <StyledInputBase
                              placeholder="Search…"
                              inputProps={{ 'aria-label': 'search' }}
                              />
                          </Search> */}

                          {/* Top SERVERLESS & DEDICATED actionable buttons. Only show if user is logged in */}
                          {UserState.isAuthenticated && (
                            <Box component="div" sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                              <NavbarActions />
                            </Box>
                          )}
                      </Box>
                      <Box sx={{ flexGrow: 1 }} />

                      {UserState.isAuthenticated && (
                        <>
                          <Box style={{ alignItems: "center" }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Tooltip title="Pricing page">
                              <NavLink 
                                key="Price"
                                to="/price"
                                // onClick={() => handleChange(item, index)}
                                aria-current="page" 
                                style={{textTransform :"none"}}
                                className="text-sm text-white hover:bg-blue-200 hover:text-gray-600 rounded-md px-3 py-2"
                              >
                                Pricing
                              </NavLink>
                            </Tooltip>
                              {/* <Box className="bg-white dark:bg-slate-500" style={{height: "fit-content"}}>
                                <Button onClick={handleCreateInviteRoute} color="info" variant="outlined" endIcon={<Drafts />} size="small"  style={{borderRadius: "unset", textTransform :"none"}}>
                                  Create Invite
                                </Button>
                              </Box> */}
                              
                              {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 10px", backgroundColor: "#fff"}} /> */}
                              {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                                <Badge badgeContent={4} color="error">
                                    <MailIcon />
                                </Badge>
                              </IconButton>
                              <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                              >
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon />
                                </Badge>
                              </IconButton> */}
                              {/* <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                              >
                                <AccountCircle />
                                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                              </IconButton> */}

                              <Tooltip title="More settings">
                                <IconButton
                                  size="large"
                                  edge="end"
                                  sx={{ ml: 2 }}
                                  aria-controls={menuId}
                                  aria-haspopup="true"
                                  // aria-expanded={open ? 'true' : undefined}
                                  onClick={handleProfileMenuOpen}
                                >
                                  <Avatar sx={{ width: 32, height: 32 }}>{UserState.activeAccount.accountName?.charAt(0).toUpperCase()}</Avatar>
                                  <Stack className="ml-2" direction="column" alignItems="flex-start">
                                    <Typography
                                        className="text-white"
                                        variant="body1"
                                        noWrap
                                        sx={{ display: { xs: 'none', sm: 'block' } }}
                                    >
                                        {UserState.user.username}
                                    </Typography>
                                    <Typography
                                        className="text-white"
                                        variant="body2"
                                        noWrap
                                        sx={{ display: { xs: 'none', sm: 'block' } }}
                                    >
                                      {UserState.activeAccount.accountName}
                                    </Typography> 
                                  </Stack>
                                </IconButton>
                              </Tooltip>
                          </Box>
                          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                              <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                              >
                                <MoreIcon />
                              </IconButton>
                          </Box>
                          {renderMobileMenu}
                          {renderMenu}
                        </>
                      )}
                  </Toolbar>
              </AppBar>
          {/* </ThemeProvider> */}
          {/* : showLogoutContent} */}
        </Box>
    </div>
  );
}
