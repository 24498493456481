import * as React from 'react';
// import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
// import SpeedSharpIcon from '@mui/icons-material/SpeedSharp';
import Tooltip from '@mui/material/Tooltip';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';


interface Props {}
const PriceDedicatedSampleTableFull: React.FC<Props> = () => {
    return (
    <>
    <div className='text-sm italic'>
    Sample pricing for custom dedicated deployment
    </div>
    <table>
      <thead className="bg-sky-50" style={{borderBottom: "1px solid black", textAlign: "left"}}>
        <tr>
          <th style={{padding: "5px"}}>Unit Compute Node</th>
          <th className="text-sm">Cost per Node</th>
          <th className="text-sm">
            <Tooltip title="Throughput subject to change based upon dataset and number of current users">
              <span>Total Throughput <InfoSharpIcon fontSize="small" /></span>
            </Tooltip>
          </th>
          <th className="text-sm" style={{justifyContent: "space-between", display: "flex", width: "200px"}}>
            <span>Cost</span> <span>{`<->`}</span> <span>Latency</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>A100-40GB x 1</td>
          <td className="text-sm">$1.50</td>
          <td className="text-sm">368 tokens/s</td>
          <td className="text-sm" style={{position: "relative"}}>
            <div style={{border: '1px solid black', borderTop: "0", borderBottom: "0", height: "12px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div style={{ position: "absolute", left: "1px", top: "10px", border: "0", borderTop: "1px solid black", height: "1px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div className="bg-blue-500 divide-blue-500" style={{ height: "12px", width: "12px", position: "absolute", left: "40px", top: "5px", display: "inline-block"}}>
               &nbsp;
            </div>
          </td>
        </tr>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>A100-40GB x 4</td>
          <td className="text-sm">$3.69</td>
          <td className="text-sm">736 tokens/s</td>
          <td className="text-sm" style={{position: "relative"}}>
            <div style={{border: '1px solid black', borderTop: "0", borderBottom: "0", height: "12px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div style={{ position: "absolute", left: "1px", top: "10px", border: "0", borderTop: "1px solid black", height: "1px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div className="bg-blue-500 divide-blue-500" style={{ height: "12px", width: "12px", position: "absolute", left: "80px", top: "5px", display: "inline-block"}}>
               &nbsp;
            </div>
          </td>
        </tr>                    
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>H100-80GB x 1</td>
          <td className="text-sm">$2.50</td>
          <td className="text-sm">500 tokens/s</td>
          <td className="text-sm" style={{position: "relative"}}>
            <div style={{border: '1px solid black', borderTop: "0", borderBottom: "0", height: "12px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div style={{ position: "absolute", left: "1px", top: "10px", border: "0", borderTop: "1px solid black", height: "1px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div className="bg-blue-500 divide-blue-500" style={{ height: "12px", width: "12px", position: "absolute", left: "120px", top: "5px", display: "inline-block"}}>
               &nbsp;
            </div>
          </td>
        </tr>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>H100-80GB x 2</td>
          <td className="text-sm">$5.00</td>
          <td className="text-sm">750 tokens/s</td>
          <td className="text-sm" style={{position: "relative"}}>
            <div style={{border: '1px solid black', borderTop: "0", borderBottom: "0", height: "12px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div style={{ position: "absolute", left: "1px", top: "10px", border: "0", borderTop: "1px solid black", height: "1px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div className="bg-blue-500 divide-blue-500" style={{ height: "12px", width: "12px", position: "absolute", left: "160px", top: "5px", display: "inline-block"}}>
               &nbsp;
            </div>
          </td>
        </tr>
        <tr>
          <td className="text-sm" style={{padding: "5px"}}>H100-80GB x 4</td>
          <td className="text-sm">$10.00</td>
          <td className="text-sm">1000 tokens/s</td>
          <td className="text-sm" style={{position: "relative"}}>
            <div style={{border: '1px solid black', borderTop: "0", borderBottom: "0", height: "12px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div style={{ position: "absolute", left: "1px", top: "10px", border: "0", borderTop: "1px solid black", height: "1px", display: "inline-block", width: "200px"}}>
              &nbsp;
            </div>
            <div className="bg-blue-500 divide-blue-500" style={{ height: "12px", width: "12px", position: "absolute", left: "190px", top: "5px", display: "inline-block"}}>
               &nbsp;
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </>
    );
}

export default PriceDedicatedSampleTableFull;