import * as React from 'react';

interface Props {}

const PriceBatchSampleTable: React.FC<Props> = () => {
    return (
    <>
    <div className='font-bold text-lg'>
      Hourly Price per Batch Job
    </div>
    <table style={{minWidth: "400px"}}>
      <thead className="bg-sky-50" style={{borderBottom: "1px solid black"}}>
        <tr style={{textAlign: "left"}}>
          <th style={{padding: "5px"}}>Example Pricing</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>Endpoint:</td>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>mistralai/Mistral-7B-Instruct-v0.1</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>Number of prompts:</td>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>1,000,000</td>
        </tr>                    
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>Cost per hour:</td>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>$1.50</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>Provisioned GPU:</td>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>A100-40GB SXM</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-xs" style={{padding: "5px 0px 15px 5px"}} colSpan={2}>
            <p className="italic text-xs" style={{width: "300px"}}>
            Note: we automatically select the GPU  in our fleet with the best output per $ 
            </p>
          </td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>Total processing time:</td>
          <td className="text-sm" style={{padding: "5px 0px 15px 5px"}}>23 minutes</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-xs" style={{padding: "5px 0px 15px 5px"}} colSpan={2}>
            <p className="italic text-xs" style={{width: "300px"}}>
              Note: the only factor that affects cost is GPU  cost per hour and GPU time.
            </p>
          </td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <th className="text-sm font-bold" style={{padding: "5px 0px 15px 5px"}}>Total Cost:</th>
          <td className="text-sm font-bold" style={{padding: "5px 0px 15px 5px"}}>$.0.57</td>
        </tr>
      </tbody>
    </table>
    </>
    );
}

export default PriceBatchSampleTable;