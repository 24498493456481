import * as React from 'react';
interface Props {}

const PriceServerlessSampleTable: React.FC<Props> = () => {
    return (
    <>
    <div className='font-bold text-lg'>
      Price per million tokens
    </div>
    <table style={{minWidth: "450px"}}>
      <thead className="bg-sky-50" style={{borderBottom: "1px solid black"}}>
        <tr style={{textAlign: "left"}}>
          <th style={{padding: "5px"}}>Model</th>
          <th>$/m input tokens</th>
          <th>$/m output tokens</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>mistral-7b-instruct / 0.3</td>
          <td className="text-sm">$0.06</td>
          <td className="text-sm">$0.06</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>mixtral-8x7b-instruct / 0.1</td>
          <td className="text-sm">$0.24</td>
          <td className="text-sm">$0.24</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>llama-3-8b-instruct / 0.1</td>
          <td className="text-sm">$0.06</td>
          <td className="text-sm">$0.06</td>
        </tr>                    
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>...</td>
          <td className="text-sm">...</td>
          <td className="text-sm">...</td>
        </tr>
        <tr style={{textAlign: "left"}}>
          <td className="text-sm" style={{padding: "5px"}}>...</td>
          <td className="text-sm">...</td>
          <td className="text-sm">...</td>
        </tr>
      </tbody>
    </table>
    </>
    );
}

export default PriceServerlessSampleTable;