
import * as React from 'react';
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NavLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { useNavigate  } from "react-router-dom";

// Parasail imports
import PriceServerlessSampleTable from "components/views/common/PriceServerlessSampleTable";
import PriceDedicatedSampleTable from "components/views/common/PriceDedicatedSampleTable";
import PriceBatchSampleTable from "components/views/common/PriceBatchSampleTable";
import PriceBatchSampleTableSecondary from "components/views/common/PriceBatchSampleTableSecondary";
import PriceDedicatedSampleTableFull from "components/views/common/PriceDedicatedSampleTableFull";


interface PriceType { 
  id: number,
  name: string,
  link: string,
  current: boolean,
  componentSampleTable?: React.ReactNode,
  componentSampleTableSecondary?: React.ReactNode,
  componentSampleTableFull?: React.ReactNode,
  summary: string,
  detailsTitle: string,
  details: string
};

const navigation = [
  { 
    id: 0,
    name: 'Serverless',
    link: '/serverless',
    current: true, 
    componentSampleTable: <PriceServerlessSampleTable/>,
    summary: 'Price-per-token to ensure you only pay for the compute you actually use, offering flexibility and cost-efficiency at scale.',
    detailsTitle: 'Serverless Endpoints',
    details: 'Price-per-token to ensure you only pay for the compute you actually use, offering flexibility and cost-efficiency at scale.'
  },
  { 
    id: 1,
    name: 'Dedicated',
    link: '/dedicated',
    current: false, 
    componentSampleTable: <PriceDedicatedSampleTable/>,
    componentSampleTableFull: <PriceDedicatedSampleTableFull/>,
    summary: 'Hourly pricing for dedicated GPUs gives you predictable costs and full control  over high-performance resources tailored to your workload.', 
    detailsTitle: 'Dedicated Instances',
    details: 'Our dedicated instances are priced by GPU per hour. We offer various configurations of our hardware fleet to hit your indicated cost, performance, and latency targets. Our dedicated instances autoscale the number of GPUs as your workload fluctuates, but we offer scale-down policy configuration to meet your needs.'
  },
  { 
    id: 2,
    name: 'Batch Processing',
    link: '/batch',
    current: false,
    componentSampleTable: <PriceBatchSampleTable/>,
    componentSampleTableSecondary: <PriceBatchSampleTableSecondary/>,
    summary: 'Optimize batch processing with our fast and low-cost API using your preferred models and embeddings.',
    detailsTitle: 'Batch Processing',
    details: 'Our self-service batch processing API offers the best pricing and speeds for your largest jobs. We identify a unique configuration of our fleet to deliver the best value for your needs. Track progress and view the estimated cost as soon as you queue the batch job.'
  },
]

const PriceView = () => {
  const [ currentItem, setCurrentItem ] = React.useState<PriceType>(navigation[0]);
  const [ currentNagivation, setCurrentNagivation ] = React.useState(navigation);

  const navigate = useNavigate();

  const handleChange = (updatedItem: PriceType) => {
    // update item and update list
    if(updatedItem) {
      setCurrentNagivation(prevItems => prevItems.map(item =>
        item.id === updatedItem.id ? { ...item, current: true} : { ...item, current: false }
      ));
    }

    // save new item
    setCurrentItem(updatedItem);
  };

  console.log('currentNagivation', currentNagivation)
  return (
    <>
      <BasicBreadcrumbs primary="Pricing" />

      <div className='pl-12 pr-12'>
        <PageHeader title="Pricing" customStyles={{ color: '#1976d2', fontWeight: "500" }} />

        <div
          className='bg-white dark:bg-black'
          style={{
            minHeight: "100%",
            display: "block",
            justifyContent: "center",
          }}
        >
          <Typography gutterBottom variant="body1" component="div">
            We believe that democratizing access to compute is the first step to enabling the next wave of AI breakthroughs for all. That's why we're offering the best prices on the latest hardware and models, all
            delivered through reliable solutions that fit your needs as you scale. 
          </Typography>
          <Typography gutterBottom variant="body1" component="div" className='pt-3'>
            Our approach is simple — by building relationships with leading cloud providers, we unlock a massive abundance of compute and the best industry prices with a scalable, reliable experience. We embrace open source and its many advantages to distribute the best solutions and innovation to everyone.
          </Typography>

          <Grid container spacing={4} className="mt-6">
          {currentNagivation.map((item) => (
            <Grid key={item.id} size={{ xs: 4 }}>
              {/* <ThemeProvider
                theme={{
                  palette: {
                    primary: {
                      main: '#1976d2',
                      dark: '#0066CC',
                    },
                  },
                }}
              > */}
                <Box
                  sx={{
                    padding: 2,
                    // width: 400,
                    minHeight: 140,
                    borderRadius: 2,
                    bgcolor: item.current ? 'primary.main' : 'white',
                    '&:hover': {
                      bgcolor: 'rgb(148 163 184)',
                    },
                    border: 1,
                    borderColor: "rgb(2 132 199)",
                    color: item.current ? 'white' : 'black'
                  }}
                  onClick={() => handleChange(item)}
                >
                  <Typography 
                    variant="h6" 
                    component="h6" 
                    style={{color: item.current ? 'white' : '#1976d2', fontWeight: '500'}}
                    sx={{
                      '&:hover': {
                        color: 'white' 
                      },
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body2" component="div" className={`pt-3`}>
                    {item.summary}
                  </Typography>
                </Box>
              {/* </ThemeProvider> */}
            </Grid>
          ))}

            <Grid
              sx={{
                padding: 3,
                width: '100%',
                minHeight: 200,
                borderRadius: 2,
                bgcolor: 'white',
                border: 1,
                borderColor: "rgb(2 132 199)",
              }}
            >
            {currentItem &&
              <Grid container spacing={1} direction="row" justifyContent="space-between" >
                <Grid size={{ xs: 6, md: 6 }} style={{maxWidth: '50%'}}>
                  <Stack direction="column" alignItems="left" spacing={1}>
                    <Typography className="pb-3" variant="h5" component="h5" style={{color: '#1976d2', fontWeight: '500'}}>
                      {currentItem?.detailsTitle}
                    </Typography>
                    {currentItem?.details}
                  </Stack>
                  <Stack direction="row" className='mt-6'>
                    <Button variant="contained" onClick={() => navigate(currentItem.link)} size="small" style={{textTransform :"none"}}>
                        Get started with {currentItem?.name.toLocaleLowerCase()} now
                    </Button>
                  </Stack>

                  <Stack marginTop={3} direction="column" alignItems="left" spacing={1}>
                    {currentItem?.componentSampleTableSecondary}
                  </Stack>
                </Grid>
                
                <Grid size={{ xs: 6, md: 6 }} style={{ minWidth: '45%'}}>
                  <Stack direction="column" alignItems="left" spacing={1}>
                    {currentItem.componentSampleTable}
                  </Stack>
                </Grid>

                <Grid style={{width: '100%'}}>
                  <Stack direction="column" alignItems="left" spacing={1}>
                    {currentItem.componentSampleTableFull}
                  </Stack>
                </Grid>

                <Grid className="mt-6" style={{width: '100%'}}>
                  <Stack direction="column" alignItems="center" spacing={1}>
                    <Typography variant="body1" component="div">
                      Need enterprise-grade support?
                      <NavLink to={`/invite`} /*state={dedicatedDeployment || {}} */ className="font-bold underline text-blue-500 ml-1 mr-1">
                          Contact us
                      </NavLink>to learn more about our enterprise offering.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            } 
            </Grid>
            
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PriceView;