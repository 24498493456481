import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import { CopyBlock } from 'react-code-blocks';

// Parasail imports
import { Logger } from "utils/Logger";
import { useUserContext } from "components/providers/UserContext";
import { ApiKey, Account } from "react-app-env";
import {  FetchKeyAPI } from "service/FetchService";

interface Props { 
    currentKeys?: ApiKey[]
    setCurrentKeys?: (value: React.SetStateAction<ApiKey[]>) => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const GeneralCreateKey: React.FC<Props> = ({ currentKeys, setCurrentKeys }) => {
    const [openCreateKey, setOpenCreateKey] = React.useState(false);
    const [key, setKey] = React.useState<ApiKey>(Object);

    const { UserState, isUserContextLoading } = useUserContext();
    
    const handleCloseCreateKey = () => setOpenCreateKey(false);
    const handleOpenCreateKey = () => {
      Logger.log('handleCreateKey');
  
      setOpenCreateKey(true);
      const activeAccount = UserState.activeAccount as Account;
  
      // Generate key
      FetchKeyAPI.createKey(activeAccount).then((response) => {
        Logger.log('createKey response', response);

        if(response) {
          // Save new key to show in modal
          setKey(response);
  
          // Get latest key list
            if(setCurrentKeys && currentKeys) {
                FetchKeyAPI.getKeys().then((response) => {
                    Logger.log('getKeys response', response);
                    
                    if(response) {
                        setCurrentKeys(response);
                    }
                }).catch((e) => {
                Logger.log('Get keys error: ', e.code);
                });
            }
        }
      }).catch((e) => {
        Logger.log('Generate key error: ', e.code);
      });
    }

    if (isUserContextLoading) {
        return <div></div>;
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={handleOpenCreateKey}
                size="small"
                style={{textTransform :"none"}}
            >
                Create new API key
            </Button>

            <Modal
                open={openCreateKey}
                onClose={handleCloseCreateKey}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    New API Key
                </Typography>
                <div className="mt-2 mb-2">Please save this key for future use. You will not see this key again.</div>
                <Grid style={{ paddingLeft: "0", alignContent: "center" }}>
                    {key && Object.keys(key).length !== 0 ? (
                    <Grid container>
                        <Grid sx={{minWidth: '100%', backgroundColor: "#f7f6f9"}}>  
                        <CopyBlock
                            text={key?.token || ""}
                            language={"plaintext"}
                            showLineNumbers={false}
                            wrapLongLines={true}
                        />
                        </Grid>
                    </Grid>
                    ) : <div className="mt-2 mb-2"></div>}
                </Grid>
                <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
                    <Button 
                        variant="outlined" 
                        onClick={handleCloseCreateKey} 
                        size="small"
                    >
                     Close
                    </Button>
                </Stack>
                </Box>
            </Modal>
        </>
    );
}

export default GeneralCreateKey;