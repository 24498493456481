import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import { useLoaderData } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Modal from '@mui/material/Modal';
import moment from 'moment'; 
import Grid from '@mui/material/Grid2';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Cancel from '@mui/icons-material/Cancel';

// Parasail imports
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";
import ModelUsagesPane from "components/views/common/ModelUsagesPane";
import BatchCreateModal from "components/views/common/BatchCreateModal";
import { Logger } from "utils/Logger";
import { 
  FetchBatchAPI 
} from "service/FetchService";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AccordionCustom = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummaryCustom = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetailsCustom = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, text: string }) {
  return (
  <div>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >{props.text}</Typography>
    </Box>
  </div>
  );
}

const BorderLinearProgressWithLabel = styled(LinearProgressWithLabel)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: '#1a90ff',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));
const GET_DELOYMENTS_TIMER = 20000;// every 20 seconds

const BatchHomeView = () => {
  const [currentBatchData, setCurrentBatchData] = React.useState<any>();
  const [currentBatch, setCurrentBatch] = React.useState<any>();
  const [batchCancelOpen, setBatchCancelOpen] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  // const [progress, setProgress] = React.useState(12);
  // const [hasProgressError, setHasProgressError] = React.useState(false);
  // const [isProgressComplete, setIsProgressComplete] = React.useState(false);

  // for popover tooltip
  const [batchTooltipText, setBatchTooltipText] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, text: React.SetStateAction<string>) => {
    setBatchTooltipText(text);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const batchData = useLoaderData() as any;

  React.useEffect(() => {
    Logger.log(batchData);

    // save batch data from route loader
    if (batchData) {
      setCurrentBatchData(batchData as any);
    }
  }, [batchData]);

  // refetch batches every 20 secs
  const callRefetchBatchData = React.useCallback(async () => {
    Logger.log('Calling latest batch data (20 secs)...');

    setShowLoader(currShowLoader => !currShowLoader);
    const res2 = await FetchBatchAPI.getBatches();
    const batches = res2 as any[];

    const latestBatchData = { batches };
    Logger.log("latestBatchData", latestBatchData);

    // Update latest batch data
    setCurrentBatchData(latestBatchData);
    setShowLoader(currShowLoader => !currShowLoader);
  }, []);

  React.useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      callRefetchBatchData();
    }, GET_DELOYMENTS_TIMER);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [callRefetchBatchData]);

  // batch cancel modal open/close handlers
  const handleBatchCancelOpen = (batch: any) => {
    setCurrentBatch(batch);
    setBatchCancelOpen(true);
  }
  const handleBatchCancelClose = () => setBatchCancelOpen(false);
  
  const handleInputUrlClick = (id: string) => {
    Logger.log('Batch - handleInputUrlClick', id);
    FetchBatchAPI.getInputUrl(id).then((response) => {
      Logger.log('getInputUrl response', response, id);

      // redirect to url
      const url = response ? response?.url?.toString() : '';
      if (url) {
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleOutputUrlClick = (id: string) => {
    Logger.log('Batch - handleOutputUrlClick');
    FetchBatchAPI.getOutputUrl(id).then((response) => {
      Logger.log('getOutputUrl response', response, id);

      // redirect to url
      const url = response ? response?.url?.toString() : '';
      if (url) {
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleErrorUrlClick = (id: string) => {
    Logger.log('Batch - handleErrortUrlClick', id);
    FetchBatchAPI.getErrorUrl(id).then((response) => {
      Logger.log('getErrorUrl response', response, id);

      // redirect to url
      const url = response ? response?.url?.toString() : '';
      if (url) {
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleCancelBatchClick = () => {
    Logger.log('Batch - handleCancelBatchClick', currentBatch);
    if(currentBatch.id) {
      FetchBatchAPI.setCancel(currentBatch.id).then((response) => {
        Logger.log('setCancel response', currentBatch.id, response);
      
        // refetch batch list
        callRefetchBatchData();

        // close modal
        handleBatchCancelClose();
      }).catch((e) => {
        Logger.log(e);
      });
    }
  };

  // Table headers
  const columns: GridColDef[] = [
    { field: 'externalId', headerName: 'Batch ID', sortable: false, width: 150,
      renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ mt: 1, mb: 1 }}>        
          {params.value}
        </Box>
      );
    }},
    { field: 'createdAt', headerName: 'Dates', sortingOrder: ["desc", "asc", null], filterable: false, width: 250,
      renderCell: (params: GridRenderCellParams) => {
      const createdAt = moment.unix(params.row.createdAt);
      const completedAt = params.row.completedAt ? moment.unix(params.row.completedAt) : null;
      const createdAtFormatted = createdAt.fromNow(false);
      const createdAtFullFormatted = createdAt?.format("dddd, Do MMM YYYY, h:mm:ss A");
      const completedAtFormatted = completedAt ? completedAt?.fromNow(false) : null;
      const completedAtFullFormatted = completedAt ? completedAt.format("dddd, Do MMM YYYY, h:mm:ss A") : null;

       return (
        <Box sx={{ mt: 1, mb: 1 }}>        
          <div
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, createdAtFullFormatted)}
              onMouseLeave={handlePopoverClose}
          >Created {createdAtFormatted}</div>
          <br />
          {completedAtFullFormatted && completedAtFullFormatted.length !== 0 &&
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => handlePopoverOpen(e, completedAtFullFormatted)}
                onMouseLeave={handlePopoverClose}
            >Completed {completedAtFormatted}</div>
          }
        </Box>
       );
     } },
    { field: 'requestCountTotal', headerName: 'Progress', filterable: false, width: 300,
      valueGetter: (value, row) => ((row.requestCountSuccess || 0) + (row.requestCountFailed || 0)) / (row.requestCountTotal || 1),
      renderHeader: () => (
      <>
        <strong>
          {'Progress'}
        </strong>
        {showLoader &&
          <CircularProgress className="ml-3" style={{width: "25px", height: "25px"}}/>
        }
      </>
    ), renderCell: (params: GridRenderCellParams) => {
      const requestCountTotal = params.row.requestCountTotal ? params.row.requestCountTotal : 0;
      const requestCountSuccess = params.row.requestCountSuccess ? params.row.requestCountSuccess : 0;
      const requestCountFailed = params.row.requestCountFailed ? params.row.requestCountFailed : 0;
      const progress = ((requestCountSuccess + requestCountFailed) / requestCountTotal) * 100;
      const progressBase = requestCountSuccess + requestCountFailed;
      let colorCode = 'primary';
      let hasFailure = false;

      if (requestCountFailed > 0) {
        // setHasProgressError(currHasProgressError => !currHasProgressError);
        colorCode = 'warning';
        hasFailure = true;
      }
      if (progressBase === requestCountTotal) {
        // setIsProgressComplete(currIsProgressComplete => !currIsProgressComplete);
        colorCode = 'success';
      }
      // setProgress(progress);
      const text = hasFailure ? `${requestCountSuccess} completed, ${requestCountFailed} failed, out of ${requestCountTotal} total` 
      : `${requestCountSuccess} completed out of ${requestCountTotal} total` ;
     
      return (
        <Box sx={{ mt: 1, mb: 1 }}>
          {requestCountTotal ?
            <BorderLinearProgressWithLabel 
              value={progress}
              text={text}
              color={colorCode as any}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, text)}
              onMouseLeave={handlePopoverClose}
            />
          : ''}
        </Box>
      );
     } },
     { field: 'status', headerName: 'Status', width: 120,
       valueGetter: (value, row) => `${row.statusSort.toString().padStart(3, 0)} ${row.status}`,
       renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ mt: 1, mb: 1 }}>
          {params.row.status}
        </Box>
      );
    }},
    { field: 'actions', headerName: 'Actions', sortable: false, filterable: false, width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const hasInputUrl = params.row.hasInputUrl;
        const hasOutputUrl = params.row.hasOutputUrl;
        const hasErrorUrl = params.row.hasErrorUrl;
        const isCancellable = params.row.cancellable;
        const id = params.row.id;
        
        return (
          <Box sx={{ '& button': { mt: 1, mb: 1 } }}>   
            <div>
              { hasInputUrl && <Button variant="text" size="small" onClick={() => handleInputUrlClick(id)}  endIcon={<CloudDownload />} style={{minWidth: '30px', textTransform :"none"}}>Download input</Button>}
              { hasOutputUrl && <Button variant="text" size="small" onClick={() => handleOutputUrlClick(id)} endIcon={<CloudDownload />} style={{textTransform :"none"}}>Download output</Button>}
              { hasErrorUrl && <Button variant="text" size="small"  onClick={() => handleErrorUrlClick(id)} endIcon={<CloudDownload />} style={{textTransform :"none"}}>Download error</Button>}
              { isCancellable && <Button variant="contained" color="error" size="small" onClick={() => handleBatchCancelOpen(params.row)} endIcon={<Cancel />} style={{ textTransform :"none"}}>Cancel batch</Button>}
            </div>
          </Box>
        );
    } },
    { field: 'metadata',  headerName: 'Metadata', sortable: false, width: 350,
      valueGetter: (value, row) => [ // The filter function filters on this
        ...Object.keys(row.metadata),
        ...Object.values(row.metadata),
        ...Object.keys(row.autoMetadata),
        ...Object.values(row.autoMetadata),
        ...Object.keys(row.adminMetadata),
        ...Object.values(row.adminMetadata),
        ...(row.errors as Array<any>).map(error => `${error.line}: ${error.message}`),
      ].join('\n'),
      renderCell: (params: GridRenderCellParams) => {
        const metadata = params.row.metadata as any;
        const autoMetadata = params.row.autoMetadata as any;
        const adminMetadata = params.row.adminMetadata as any;
        const errors = params.row.errors as Array<any>;
        const metadataList = Object.keys(metadata);
        const autoMetadataList = Object.keys(autoMetadata);
        const adminMetadataList = Object.keys(adminMetadata);

        return (
          <Box sx={{ mt: 1, mb: 1 }} style={{ whiteSpace: "normal", wordWrap: "break-word" }}>   
            {metadataList && metadataList.length !== 0 &&
              <Stack direction="column">
                <div>{metadataList.map((key, index) => (
                  <div key={index}>
                    <span className="text-gray-500 dark:text-gray-400">{key}:</span> {metadata[key]}
                  </div>))}
                </div>
              </Stack>
            }

            {autoMetadataList && autoMetadataList.length !== 0 &&
                <Stack direction="column" className="mt-3">
                  <div>{autoMetadataList.map((key, index) => (
                      <div key={index}>
                        <span className="text-gray-500 dark:text-gray-400">{key}:</span> {autoMetadata[key]}
                      </div>))}
                  </div>
                </Stack>
            }

            {adminMetadataList && adminMetadataList.length !== 0 &&
                <Stack direction="column" className="mt-3">
                  <div><strong>Admin:</strong></div>
                  <div>{adminMetadataList.map((key, index) => (
                      <div key={index}>
                        <span className="text-gray-500 dark:text-gray-400">{key}:</span> {adminMetadata[key]}
                      </div>))}
                  </div>
                </Stack>
            }

            {errors && errors.length !== 0 &&
              <Stack direction="column" className="mt-3">
                <div><strong>Errors:</strong></div>
                <div>{errors.map(error => {
                  // const errorList = Object.keys(error);

                  // return (
                  //   <div>{errorList.map(key => (<div>{key}: {error[key]}</div>))}</div>
                  // )

                  return (
                    <div style={{color: "#ed6c02"}}>
                      {error && error.line ? `${error.line}: ${error.message}` : `${error.message}` }
                    </div>
                  )
                })}</div>
              </Stack>
            }
          </Box>
        );
    } },
  ];


  return (
    <>
      <BasicBreadcrumbs root="Home" primary="Batch" url="/home" />

      <PageHeader title="Batch" />

      <p className='mb-3'>
        Batch processing is the most efficient way to execute thousands or millions of inference requests. Parasail offers an API that is drop-in compatible with OpenAI client libraries.
      </p>

      <div className="bg-white dark:bg-black" style={{ width: '100%' }}>
        <AccordionCustom id="batch-usage-pane" className="mb-3">
          <AccordionSummaryCustom aria-controls="panel2d-content" id="panel2d-header">
            <Typography>How to use Batch</Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <p>
            A batch is an input file that contains many inference requests. Parasail will execute
            all requests together, then the results. Depending on the batch
            contents and system load, this can take from minutes to hours.
            </p>
            <p>
            Parasail supports batch inferencing against any model we're able to create a Dedicated deployment for. This
            means most models available on HuggingFace, including a huge variety of chat models (including fine-tunes),
            embedding models, and multi-modal models.
            </p>
            <PageHeader title="OpenAI Client Configuration"/>

            <p>Use any <a href="https://platform.openai.com/docs/libraries">OpenAI-compatible library</a>.</p>

            { currentBatchData && currentBatchData?.batchUsageGeneral?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageGeneral}/>
            }

            <div style={{display: "none"}}>
              {/* TODO: This is too busy for this page; move this to a separate documentation reference page.
              Also write a blog post about it.*/}

            <PageHeader title="Create Input file"/>
            <p>
            The first step is to create a batch input file. We support the OpenAI batch format.
            </p>

            <h2>Chat Completion</h2>
            <a href="/examples/batch-chat-example.jsonl">Example Chat Completion batch input file (.jsonl)</a>

            <a href="/examples/batch-chat-example-output.jsonl">(Example Chat Completion output .jsonl)</a>

            { currentBatchData && currentBatchData?.batchUsageCreateInput?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageCreateInput}/>
            }

            <h2>Embeddings</h2>
            <a href="/examples/batch-embedding-example.jsonl">Example Embedding batch input file (.jsonl)</a>

            <a href="/examples/batch-embedding-example-output.jsonl">(Example Embedding output .jsonl)</a>

            <p>
            <strong>Note:</strong> we recommend using the <a
              href="https://platform.openai.com/docs/api-reference/embeddings/create#embeddings-create-encoding_format">base64
            encoding format</a> because it is far more space efficient than the
            default float format used by the OpenAI protocol. This does require a small post-processing step of decoding
            the base64-encoded array,
            but this format uses 4x fewer bytes for an identical result. Our examples use this format, as does the
            official OpenAI client.
            </p>

            <h2>Multi Modal</h2>

            Supported, example coming soon!

            <PageHeader title="Submit Batch"/>
            Use the "Create batch" button below. Simply drag-and-drop the input file to create a batch.

            <strong>Programmatically using our OpenAI-compatible API:</strong>

            The OpenAI protocol has two steps. First upload the input file via the Files API, then create a batch that
            targets that uploaded file.

            This returns a batch ID that is used for all later steps. This ID is also visible below.

            { currentBatchData && currentBatchData?.batchUsageSubmit?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageSubmit}/>
            }

            <PageHeader title="Check on Progress (optional)"/>
            Once a batch is submitted it can be tracked below.

            <strong>Programmatically using our OpenAI-compatible API:</strong>

            { currentBatchData && currentBatchData?.batchUsageCheckStatus?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageCheckStatus}/>
            }

            <PageHeader title="Retrieve Results"/>
              <p>
            Once a batch completes the successful responses appear in an output file. A Download output button appears,
            click that to download.
              </p>
              <p>
            If there are any requests that failed (for example because they are invalid) then they will appear in a
            separate Errors file.
              </p>

            <strong>Programmatically using our OpenAI-compatible API:</strong>

            { currentBatchData && currentBatchData?.batchUsageRetrieve?.length !== 0 &&
                <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageRetrieve}/>
            }
            {/*End TODO*/}
            </div>

            <PageHeader title="Complete Example"/>

            <Grid size={{xs: 12, md: 12}}>
              <Stack direction="row">
                <Grid container spacing={0} size={{xs: 12, md: 12}}>
                  <Grid size={{xs: 12, md: 12}}>
                    {currentBatchData && currentBatchData?.batchUsageComplete?.length !== 0 &&
                        <ModelUsagesPane data={[]} usagesData={batchData?.batchUsageComplete}/>
                    }
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

        {/* Batch list */}
        <Grid size={{xs: 12, md: 12}} style={{width: '100%' }}>
          <div className="font-bold py-2 flex items-center text-lg text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
            Batch list
          </div>
          <p className="mt-1 mb-3 flex items-center text-sm text-blue-400">
            {/* Details of all batches. */}
            <BatchCreateModal callRefetchBatchData={callRefetchBatchData} />
          </p>
        </Grid>
        {currentBatchData && currentBatchData?.batches &&
          <DataGrid 
            rows={currentBatchData.batches} 
            columns={columns} 
            // https://v5.mui.com/x/react-data-grid/row-height/
            // `auto` row height is calculated based on the content.
            getRowHeight={() => 'auto'}
            autoPageSize={false}
            // getRowClassName={() => 'paxton-table--row'}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
            sx={{ '& .MuiDataGrid-scrollbar--vertical': {display: 'none' }, minHeight: '500px', '--DataGrid-overlayHeight': '300px' }}
            // checkboxSelection
            // hideFooterPagination={true}
            // hideFooter={true}
          />
        }
      </div>

      {/* modal for batch cancel confirmation */}
      <Modal
        open={batchCancelOpen}
        onClose={handleBatchCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Confirm cancel of {currentBatch?.externalId}?
          </Typography>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleBatchCancelClose} 
              loadingIndicator="Canceling..."
              size="small"
            >
              Cancel
            </LoadingButton>  
            <LoadingButton 
              variant="outlined" 
              onClick={handleCancelBatchClick} 
              // disabled={showDeployButton}
              loadingIndicator="Deleting..."
              // loading={loading}
              size="small"
            >
              Confirm
            </LoadingButton>  
          </Stack>
        </Box>
      </Modal>

      {/* batch tool tip for table */}
      <div>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{batchTooltipText}</Typography>
        </Popover>
      </div>
    </>
  );
}
export default BatchHomeView;